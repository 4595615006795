import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../routes/BaseUrl";
import parse from 'html-react-parser';
import moment from "moment";

export const GeneralInfoForm = () => {
  const token = localStorage.getItem("token");
  const { id } = useParams();
  const Navigate = useNavigate();
 
  const [errors, setErrors] = useState({});
  const [data, setData] = useState(null); 

  useEffect(() => {
    const fetchDisposisi = async () => {
      try {
        const response = await axios.get(
          BaseUrl + `/api/disposisi/print/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Replace with your actual token
            },
          }
        );
        console.log(response.data);
        setData(response.data); // Assuming response.data is an array of options
       
      } catch (error) {
        console.error("Error fetching disposisi:", error);
        setErrors({ disposisi: "Failed to get data" });
      }
    };

    fetchDisposisi();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      //window.print();
    }, 1000); // Adjust the delay as needed

    // Cleanup timeout on unmount
    return () => clearTimeout(timeoutId);
  }, []);
  
  // Check if data is loaded
    if (!data) {
        return <div>Loading...</div>; // Render loading state or placeholder
    }

    const htmlContent = `
   <html>
<head>
<style type="text/css" media="print">
	b,p {
    font-family: Verdana,sans-serif;
	font-size: 11px;
	}
	table {border: solid 2px #000; border-collapse: collapse; width: 100%}
	tr { border: solid 0px #000}
	td { padding: 7px 5px}
	h3 { margin-bottom: -17px }
	h2 { margin-bottom: 0px }
	u.dotted{
  border-bottom: 1px dashed #999;
  text-decoration: none; 
}
	
</style>
<style type="text/css" media="screen">
	b,p {
    font-family: Verdana,sans-serif;
	font-size: 11px;
	}
	table {border: solid 2px #000; border-collapse: collapse; width: 60%}
	tr { border: solid 0px #000}
	td { padding: 7px 5px}
	h3 { margin-bottom: -17px }
	h2 { margin-bottom: 0px }
	u.dotted{
 text-decoration:underline;
text-decoration-style: dotted;
}
	
</style>
</head>

<body>
<table border="0">
	<tr>
	<td colspan="5" align="right" style="padding: 20px 0;letter-spacing: 2px;">
    <b style="font-size: 21px;">LEMBAR DISPOSISI</b></td>
	<td align="right"></td>
	</tr>
	
	<tr style="border-top: solid 2px">
	<td width="14%"><b>Surat dari</b></td>
	<td width="2%"><b>:</b></td> 
	<td width="34%">${data.surat.asal_surat}</td>
	<td width="14%" style="border-left: solid 2px"><b>Diterima Tgl </b></td>
	<td width="2%"><b>:</b></td> 
	<td width="34%">${moment(data.surat.tanggal_diterima).format('DD MMMM YYYY')}</td>
	</tr>
	
	<tr>
	<td width="14%"><b>No. Surat</b></td>
	<td width="2%"><b>:</b></td> 
	<td width="34%">${data.surat.no_surat}</td>
	<td width="14%" style="border-left: solid 2px"><b>No. Agenda</b></td>
	<td width="2%"><b>:</b></td> 
	<td width="34%">${data.surat.no_agenda}</td>
	</tr>
	
	<tr style="border-bottom: solid 2px">
	<td width="14%"><b>Tgl. Surat</b></td>
	<td width="2%"><b>:</b></td> 
	<td width="34%">${moment(data.surat.tanggal_surat).format('DD MMMM YYYY')}</td>
	<td width="14%" style="border-left: solid 2px"><b>Sifat</b></td>
	<td width="2%"><b>:</b></td> 
	<td width="34%">${data.surat.sifat}</td>
	</tr>
	
	<tr style="height: 100px;border-bottom: solid 2px" valign="top">
	<td><b>Hal</b></td>
	<td><b>:</b></td>
	<td colspan="4">${data.surat.isi_ringkas}</td>
	</tr>
	
	
	<tr>
	<td colspan="3"><b>Diteruskan Kepada Sdr :</b></td>
	<td colspan="3" style="border-left: solid 2px">
	<b>Dengan hormat harap :</b>
	</td>
	</tr>
	<tr>
	<td colspan="3">
	<ol>
	  ${data.disposisi.map(dispo => `
	   &#9745;&nbsp ${dispo.penerima_nama} (${dispo.penerima_role})<br/>
	  `).join('')}
	</ol>
	</td>
	<td colspan="3" style="border-left: solid 2px">
	<ol>
	  ${data.disposisi.map(dispo => `
	  &#9745;&nbsp ${dispo.isi} <b> (${moment(dispo.created_at).format('DD MMMM YYYY')}) </b><br/>
	  `).join('')}
	</ol>
	</td>
	</tr>
	
	<tr style="height: 60px;border-top: solid 2px" valign="top">
	<td><b>Catatan :</b></td>
	<td colspan="5">
	<br/>
	<ul>
	  ${data.catatan ? data.catatan.map(catat => `
	  <li>${catat.isi_keterangan} <b>(${catat.nama})</b></li>
	  `).join('') : ''}
	</ul>
	</td>
	</tr>
	<tr style="height: 80px; border-top: 0px" valign="top">
	<td colspan="6" style="padding-left: 500px;">
	<b>Tanggal <u class="dotted">${moment().format('DD MMMM YYYY')}</u></b></td>

	</tr>
	<tr style="height: 80px;border-top: 0px" valign="top">
	<td colspan="6" style="padding-left: 500px;">
	<b> ...................................... </b></td>
	</tr>
</table>
</body>
</html>
  `; 
  
  return <div>{parse(htmlContent)}</div>;
};
