
import React, { useState,useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCog, faEnvelopeOpen, faSearch, faSignOutAlt, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { Row, Col, Nav, Form, Image, Navbar, Dropdown, Container, ListGroup, InputGroup } from '@themesberg/react-bootstrap';
import { Link, useNavigate } from "react-router-dom";

import Profile3 from "../assets/img/profile.jpg";
import { BaseUrl } from "../routes/BaseUrl";
import axios from "axios";

const Navbarsrc = (props) => {
   
   
   const [profile, setProfile] = useState([]); 
 
   const navigate = useNavigate();
   
     
   const token = localStorage.getItem("token");
   const userId = localStorage.getItem("userId"); 
   
    useEffect(() => {
   
    const fetchProfile = async () => {
   
      try {
        const response = await axios.get(
           BaseUrl + `/api/auth/profile`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data);
        setProfile(response.data);
      } catch (error) {
        console.error("Error profile:", error);
      }
    };
    fetchProfile();
  }, []);
  
   const logoutHandler = async () => {
        console.log(token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        axios.defaults.headers.common["Content-Type"] = "application/json";
        await axios.post(`${BaseUrl}/api/auth/logout`).then(() => {
            localStorage.removeItem("token");
            localStorage.removeItem("role");
            navigate("/");
        });
    };


  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            
          </div>
          <Nav className="align-items-center">
            <Dropdown as={Nav.Item}  >
              
              <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  <Dropdown.Item className="text-center text-primary fw-bold py-3">
                    View all
                  </Dropdown.Item>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Image src={Profile3} className="user-avatar md-avatar rounded-circle" />
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold"> {profile?.data?.user?.name || "Loading..."}</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
              

                <Dropdown.Divider />

                <Dropdown.Item className="fw-bold">
                 <a onClick={logoutHandler} 
                        href="#"
                   ><FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
                   </a>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};

export default Navbarsrc;
