import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from '@themesberg/react-bootstrap';
import { UserSuratMasukTable } from "../../components/Tables";
import { Link,useNavigate } from "react-router-dom";
import { BaseUrl,SuratMasukUpload } from "../../routes/BaseUrl";


export default () => {
   const [searchQuery, setSearchQuery] = useState('');
   const navigate = useNavigate();

    const handleSearch = () => {
        if (searchQuery.trim()) {
            // Navigate to the results page with the query as a parameter
            navigate(`/user/suratmasuk/search?query=${encodeURIComponent(searchQuery)}`);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };


  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item href="/user/suratmasuk/list">Surat Masuk</Breadcrumb.Item>
            <Breadcrumb.Item active>Daftar Surat </Breadcrumb.Item>
          </Breadcrumb>
          <h4>Daftar Surat Disposisi</h4>
        </div>
        
      </div>

      <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        

        <Col xs={12} lg={3} className="px-0 mb-0">
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            <Form.Control type="text" 
                placeholder="Pencarian Surat"
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={handleKeyPress}/>
          </InputGroup>
        </Col>
      </div>

    
      <UserSuratMasukTable /> 
      
    </>
  );
};
