import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
  Spinner,
} from "@themesberg/react-bootstrap";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { BaseUrl } from "../routes/BaseUrl";
import { toast } from "react-toastify";
import Autosuggest from "react-autosuggest";

export const GeneralInfoForm = () => {
  const { id,suratId } = useParams();
  const token = localStorage.getItem("token");
  const year  = localStorage.getItem("year");
  const Navigate = useNavigate();
  const [formData, setFormData] = useState({
    penerima: "",
    pejabat: "",
    isi: "",
    catatan: "",
    sifat: ""
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
  
  const newErrors = {};
  
   if (!formData.penerima)
   newErrors.penerima = "Pilih Penerima Disposisi";
   if (!formData.sifat)
   newErrors.sifat = "Pilih Sifat Disposisi";
   if (!formData.isi)
   newErrors.isi = "Masukan Isi Disposisi";
   setErrors(newErrors);
   return Object.keys(newErrors).length === 0;
  };

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const userId = localStorage.getItem("userId");
  
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await axios.get(BaseUrl + `/api/disposisi/penerima/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Replace with your actual token
          },
        });
        setOptions(response.data.data); // Assuming response.data is an array of options
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchOptions();
  }, []);
  
    useEffect(() => {
    const fetchDisposisi = async () => {
      try {
        const response = await axios.get(
          BaseUrl + `/api/disposisi/dispshow/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Replace with your actual token
            },
          }
        );
        setFormData(response.data); // Assuming response.data is an array of options
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching disposisi:", error);
      }
    };

    fetchDisposisi();
  }, []);

  
    
 const handleFormSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      console.log(formData);
      const response = await axios.post(
        BaseUrl + `/api/disposisi/update/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add your token here
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        Navigate(`/user/disposisi/keluar/${suratId}`);
      } else if (response.data.status == "failed") {
        toast.error(response.data.message);
        Navigate(`/user/disposisi/edit/${id}`);
        console.log("API is not working");
      } else {
        toast.error(response.data);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };


  const CreateUserRole = () => {
    return (
      <>
        <h5 className="mb-4">Edit Disposisi</h5>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="tujuan">
              <Form.Label>Penerima Disposisi</Form.Label>
              <Form.Control
                required
                as="select"
                value={formData.penerima}
                onChange={(e) =>
                  setFormData({ ...formData, penerima: e.target.value })
                }
                isInvalid={!!errors.penerima}
              >
                <option value="">Pilih Akun</option>
                 {options.map((option, index) => (
                  <option key={index} value={option.penerima_id}>
                    {option.nama_penerima} [ {option.role} ]
                  </option>
                ))}
                </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.tujuanpenerima}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
             <Form.Group id="sifatDisposisi">
              <Form.Label>Sifat Disposisi</Form.Label>
              <Form.Control
                required
                as="select"
                value={formData.sifat}
                onChange={(e) =>
                  setFormData({ ...formData, sifat: e.target.value })
                }
                isInvalid={!!errors.sifat}
              >
                <option value="">Pilih Sifat Disposisi</option>
                <option value="Biasa">Biasa</option>
                <option value="Segera">Segera</option>
                <option value="Perlu Perhatian Khusus">Perlu Perhatian Khusus</option>
                <option value="Perhatian Batas Waktu">Perhatian Batas Waktu</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.sifat}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
         <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="isiDisposisi">
              <Form.Label>Isi Disposisi</Form.Label>
              <Form.Control
                required
                as="textarea" 
                rows="3"
                placeholder="Masukan Isi Disposisi"
                value={formData.isi}
                onChange={(e) =>
                  setFormData({ ...formData, isi: e.target.value })
                }
                isInvalid={!!errors.isi}
              />
              <Form.Control.Feedback type="invalid">
                {errors.isi}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
             <Form.Group id="catatan">
              <Form.Label>Catatan</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Masukan Catatan Disposisi"
                value={formData.catatan}
                onChange={(e) =>
                  setFormData({ ...formData, catatan: e.target.value })
                }
                isInvalid={!!errors.cpassword}
              />
              <Form.Control.Feedback type="invalid">
                {errors.catatan}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
         
         
        </Row>
        
        <div className="mt-3">
          <Button
            variant="primary"
            disabled={loading}
            onClick={handleFormSubmit}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Simpan"
            )}
          </Button>
        </div>
      </>
    );
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form onSubmit={handleFormSubmit}>{CreateUserRole()}</Form>
      </Card.Body>
    </Card>
  );
};
