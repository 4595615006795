import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../routes/BaseUrl";
import moment from "moment"; 

export const GeneralInfoForm = () => {
  const token = localStorage.getItem("token");
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    noAgenda: "",
    kodeKlasifikasi: "",
    asalSurat: "",
    indeksBerkas: "",
    nomorSurat: "",
    tanggalSurat: "",
    perihal: "",
    tanggalDiterima: "",
    isiRingkas: "",
    fileSurat: null,
    sifatSurat: "",
    keterangan: "",
    tujuanSurat: [],
    namaPenerima: "",
    tanggalCetak: "",
    noTelpPenerima: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchSuratMasuk = async () => {
      try {
        const response = await axios.get(
          `${BaseUrl}/api/suratkeluar/show/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Replace with your actual token
            },
          }
        );
        setFormData(response.data); // Assuming response.data is an object
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching surat masuk:", error);
        setErrors({ suratmasuk: "Failed to get data" });
      }
    };

    fetchSuratMasuk();
  }, [id, token]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.print();
    }, 1000); // Adjust the delay as needed

    // Cleanup timeout on unmount
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div>
      <style>
        {`
          html {
            padding: 0 1em;
          }
          body {
            background-color: #FFF;
            padding: 1em;			
            margin: 1em auto;
            max-width: 70em;
            font-family: Helvetica, Verdana, Arial, sans-serif;
          }
          abbr, acronym {
            border-bottom: 1px dotted #333;
            cursor: help;
          }	
          address {
            font-style: italic;
            color: #333;
            font-size: .9em;
          }
          .header img {
            float: left;
            border: 0px solid #000;
            margin-top: 15px;
          }
          .header .qrcode {
            float: right;
            margin-top: 15px;
          }
          .header h1 {
            position: relative;
            top: 15px;
            border: 0px solid #000;
          }
          h1 {
            font-size: 0.7em;
            text-align: center;
          }
          h2 {
            clear: both;
            font-size: 0.7em;
            font-weight: bold;
            margin-top: 2em;
            font-variant: small-caps;
            padding-left: .20em;
            letter-spacing: .06em;
          }
          h3 {
            margin: 1em 0 0 0;
            font-size: 1.0em;
          }
          .line {
            font-variant: small-caps;
            letter-spacing: .06em;
            border-bottom: 1px solid #000;
            border-top: 1px solid #000;
            padding: 5px;
            font-size: 0.9em;
            margin-top: 35px;
          }
          .hasil {
            font-variant: small-caps;
            letter-spacing: .06em;
            padding: 3px;
            font-size: 0.8em;
            margin-bottom: 3px;
          } 	
          .foot {
            font-variant: small-caps;
            letter-spacing: .06em;
            padding: 5px;
            font-size: 0.8em;
            margin-bottom: 10px;
          } 
          .ttd_header {
            padding: 5px;
            font-size: 0.8em;
          } 	
          .ttd_footer {
            padding: 5px;
            font-size: 0.8em;
            margin-top: 70px;
          } 		
          .ttd_nip {
            padding: 5px;
            font-size: 0.8em;
          } 		
          .checklist {
            margin-bottom: 10px;
            margin-top: 15px;
            font-size: 0.9em;
          } 	
          .peneliti {
            font-size: 0.7em;   
          }	
        `}
      </style>

      <div className="header">
        <h1>PEMERINTAH KABUPATEN CIREBON</h1>
        <h1>SEKRETARIAT DAERAH</h1>
        <h1>Jln. SUNAN KALIJAGA NO.9, SUMBER, CIREBON</h1>
      </div>

      <h2 align="center">TANDA TERIMA</h2>
      <div className="peneliti">
        <table>
          <tbody>
            <tr>
              <td>Tujuan Surat</td>
              <td>:</td>
              <td>{formData.tujuanSurat}</td>
            </tr>
            <tr>
              <td width="130">Tanggal Surat</td>
              <td>:</td>
              <td>{moment(formData.tanggalSurat).format("DD MMMM YYYY")}</td>
            </tr>
            <tr>
              <td>Nomor Surat</td>
              <td>:</td>
              <td>{formData.nomorSurat}</td>
            </tr>
            <tr>
              <td>Banyaknya Surat</td>
              <td>:</td>
              <td>1</td>
            </tr>
            <tr>
              <td>Perihal</td>
              <td>:</td>
              <td>{formData.perihal}</td>
            </tr>
            <tr>
              <td>Kontak Person</td>
              <td>:</td>
              <td></td>
            </tr>
            <tr>
              <td>Nama</td>
              <td></td>
              <td>{formData.namaPengirim}</td>
            </tr>
            <tr>
              <td>NIP / No KTP</td>
              <td></td>
              <td>{formData.idPengirim}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="ttd_header" align="center">Cirebon, {moment(formData.tanggalCetak).format("Do MMMM YYYY")}</div>
      <div className="ttd_footer" align="center">( <u></u> )</div>
    </div>
  );
};
