import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from '@themesberg/react-bootstrap';
import { AccountTable } from "../../components/Tables";
import { Link,useNavigate } from "react-router-dom";
import { BaseUrl,UploadUrl } from "../../routes/BaseUrl";


export default () => {
   

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item href="/admin/users/account">Akun</Breadcrumb.Item>
            <Breadcrumb.Item active>Daftar Akun </Breadcrumb.Item>
          </Breadcrumb>
          <h4>Daftar Akun Pengguna</h4>
        </div>
        
      </div>

      <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0">
          <div className="me-lg-3">
            <Button as={Link} to="/admin/users/account/create" variant="secondary" className="text-dark me-2">
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              <span>Tambah Akun </span>
            </Button>
          </div>
         
        </Col>

        <Col xs={12} lg={3} className="px-0 mb-0">
          
        </Col>
      </div>

      <AccountTable /> 
    
      
    </>
  );
};
