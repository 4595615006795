import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxOpen,
  faCartArrowDown,
  faChartPie,
  faChevronDown,
  faClipboard,
  faCommentDots,
  faFileAlt,
  faPlus,
  faRocket,
  faStore,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Button, Dropdown, Breadcrumb } from "@themesberg/react-bootstrap";
import { GeneralInfoForm } from "../../components/FormsSuratKeluarKendali";


export default () => {
  return (
    <>
   
          <GeneralInfoForm />
       
    </>
  );
};

