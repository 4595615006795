import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../routes/BaseUrl";
import parse from 'html-react-parser';

export const GeneralInfoForm = () => {
  const token = localStorage.getItem("token");
  const { id } = useParams();
  const Navigate = useNavigate();
  const [formData, setFormData] = useState({
    noAgenda: "",
    kodeKlasifikasi: "",
    asalSurat: "",
    indeksBerkas: "",
    nomorSurat: "",
    tanggalSurat: "",
    perihal: "",
    tanggalDiterima: "",
    isiRingkas: "",
    fileSurat: null,
    sifatSurat: "",
    keterangan: "",
    tujuanSurat: [],
    namaPenerima: "",
    tanggalCetak: "",
    noTelpPenerima: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchSuratMasuk = async () => {
      try {
        const response = await axios.get(
          BaseUrl + `/api/suratmasuk/show/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Replace with your actual token
            },
          }
        );
        setFormData(response.data); // Assuming response.data is an array of options
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching surat masuk:", error);
        setErrors({ suratmasuk: "Failed to get data" });
      }
    };

    fetchSuratMasuk();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.print();
    }, 1000); // Adjust the delay as needed

    // Cleanup timeout on unmount
    return () => clearTimeout(timeoutId);
  }, []);

    const htmlContent = `
    <html>
      <head>
        <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/normalize/3.0.3/normalize.css">
        <style>
          @page { margin: 0 }
          body { margin: 0 }
          .sheet {
            margin: 0;
            overflow: hidden;
            position: relative;
            box-sizing: border-box;
            page-break-after: always;
          }
          body.A3           .sheet { width: 297mm; height: 419mm }
          body.A3.landscape .sheet { width: 420mm; height: 296mm }
          body.A4           .sheet { width: 210mm; height: 296mm }
          body.A4.landscape .sheet { width: 297mm; height: 209mm }
          body.A5           .sheet { width: 148mm; height: 209mm }
          body.A5.landscape .sheet { width: 210mm; height: 147mm }
          .sheet.padding-10mm { padding: 10mm }
          .sheet.padding-15mm { padding: 15mm }
          .sheet.padding-20mm { padding: 20mm }
          .sheet.padding-25mm { padding: 25mm }
          @media screen {
            body { background: #e0e0e0 !important;}
            .sheet {
              background: white;
              box-shadow: 0 .5mm 2mm rgba(0,0,0,.3);
              margin: 5mm;
            }
          }
          @media print {
            body.A3.landscape { width: 420mm }
            body.A3, body.A4.landscape { width: 297mm }
            body.A4, body.A5.landscape { width: 210mm }
            body.A5                    { width: 148mm }
          }
          table {
            border-collapse: collapse;
            border: 3px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            display: table;
            font-size: 13px;
          }
          .msg {
            letter-spacing: 1px;
            white-space: nowrap;
            font-family: Verdana;
            font-size: 12px;
            -moz-transform: rotate(-90deg);
            -moz-transform-origin: center center;
            -webkit-transform: rotate(-90deg);
            -webkit-transform-origin: center center;
            -ms-transform: rotate(-90deg);
            -ms-transform-origin: center center;
            width: 70px;
            margin-top: 170px;
            border: 1px #000;
          }
          b,p {
            font-family: Verdana,sans-serif;
            font-size: 10px;
          }
          td { padding: 7px 5px; }
          h3 { margin-bottom: -17px }
          h2 { margin-bottom: 0px }
          u.dotted {
            border-bottom: 1px solid #999;
            text-decoration: none;
          }
          @page { size: 160mm 105mm }
          body.surat .sheet { width: 160mm; height: 105mm }
          @media print { body.surat { width: 160mm } }
        </style>
      </head>
      <body class="surat">
        <section class="sheet padding-10mm">
          <table border="0">
            <tr>
              <td rowspan="8">
                <div class="msg">PEMERINTAH KABUPATEN CIREBON
                  <div style="font-size:15px; margin-left:45px;" ><b>SEKRETARIAT DAERAH</b></div>
                  <div style="font-size:13px;margin-left:40px" >KARTU SURAT MASUK</div>
                </div>
              </td>
              <td rowspan="8" style="border-right: solid 1px">
                <tr style="border-bottom: solid 1px">
                  <td ><b>Indeks</b></td>
                  <td ><b> : </b></td>
                  <td style="border-right: solid 1px">${formData.indeksBerkas !== undefined ? formData.indeksBerkas : '-'}</td>
                  <td style="border-right: solid 1px"><b>Kode : </b>${formData.kodeKlasifikasi}</td>
                  <td><b>No Urut : </b>${formData.noAgenda}</td>
                </tr>
                <tr style="border-bottom: solid 1px">
                  <td><b>Perihal</b></td>
                  <td><b> : </b></td>
                  <td colspan="6">${formData.perihal}</td>
                </tr>
                <tr style="border-bottom: solid 1px">
                  <td><b>Isi Ringkas</b></td>
                  <td><b> : </b></td>
                  <td colspan="6">${formData.isiRingkas}</td>
                </tr>
                <tr style="border-bottom: solid 1px">
                  <td><b>Dari</b></td>
                  <td><b> : </b></td>
                  <td colspan="6">${formData.asalSurat}</td>
                </tr>
                <tr style="border-bottom: solid 1px">
                  <td><b>Tgl Surat</b></td>
                  <td><b> : </b></td>
                  <td style="border-right: solid 1px">${formData.tanggalSurat}</td>
                  <td style="border-right: solid 1px"><b>No Surat : </b><br/>${formData.nomorSurat}</td>
                  <td><b>Lampiran :</b></td>
                </tr>
                <tr style="border-bottom: solid 1px">
                  <td><b>Pengolah</b></td>
                  <td><b> : </b></td>
                  <td style="border-right: solid 1px">${formData.pengolah !== undefined ? formData.pengolah: '-' }</td>
                  <td style="border-right: solid 1px"><b>Tanggal Diteruskan : </b><br/>${formData.tanggalSurat}</td>
                  <td><b>Tanda Terima :</b></td>
                </tr>
                <tr style="border-top: solid 1px" valign="top" >
                  <td><b>Catatan</b></td>
                  <td><b> : </b></td>
                  <td colspan="6"></td>
                </tr>
              </td>
            </tr>
          </table>
        </section>
      </body>
    </html>
  `; 
     return <div>{parse(htmlContent)}</div>;
};
