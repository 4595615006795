import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome,faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Breadcrumb, Table, Card, Container, Button  } from "@themesberg/react-bootstrap";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { BaseUrl,SuratMasukUpload,SuratKeluarUpload } from "../../routes/BaseUrl";
import moment from "moment";
import DocumentViewer from '../../components/DocumentViewer';

export default () => {
  const { id } = useParams();
  console.log(id);
  const [suratDetails, setSuratDetails] = useState(null);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [file, setFile] = useState(null);
  
  const handleOpenViewer = (file) => {
        setFile(file);
        setIsViewerOpen(true);
    };

    const handleCloseViewer = () => {
        setIsViewerOpen(false);
        setFile(null);
    };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchSuratDetails = async () => {
      try {
        const response = await axios.get(
           BaseUrl + `/api/suratmasuk/show/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const surat = response.data;
        console.log(surat);
        setSuratDetails(surat);
      } catch (error) {
        console.error("Error fetching surat details:", error);
      }
    };
    fetchSuratDetails();
  }, [id]);

  if (!suratDetails) {
    return <div>Loading...</div>;
  }
  
   const fullFilePath = `${SuratMasukUpload}${suratDetails.fileSurat}`;
 

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/admin/suratmasuk/list">Surat Masuk</Breadcrumb.Item>
            <Breadcrumb.Item active>{suratDetails.noAgenda}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Surat Masuk Detail</h4>
        </div>
      </div>
      
      
     
      
      <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
      
      <div className="table-responsive">

      <Table hover className="mb-8">
      <tbody>
       <tr>
        <td className="text-start"><strong>Nomor Agenda</strong></td>
        <td className="text-end">{suratDetails.noAgenda}</td>
       </tr>
       <tr>
         <td className="text-start"><strong>Asal Surat</strong></td>
         <td className="text-end">{suratDetails.asalSurat}</td>
       </tr>
       <tr>
         <td className="text-start"><strong>Nomor Surat</strong></td>
         <td className="text-end">{suratDetails.nomorSurat}</td>
       </tr>
       <tr>
         <td className="text-start"><strong>Perihal</strong></td>
         <td className="text-end">{suratDetails.perihal}</td>
       </tr>
       <tr>
         <td className="text-start"><strong>Isi Ringkas</strong></td>
         <td className="text-end text-wrap text-break">{suratDetails.isiRingkas}</td>
       </tr>
       <tr>
         <td className="text-start"><strong>Sifat Surat</strong></td>
         <td className="text-end">{suratDetails.sifatSurat}</td>
       </tr>
       <tr>
         <td className="text-start"><strong>Tujuan Surat</strong></td>
         <td className="text-end">{suratDetails.tujuanSuratNama} {suratDetails.tujuanRoleNama && `[${suratDetails.tujuanRoleNama}]`}</td>
       </tr>
       
       <tr>
         <td className="text-start"><strong>Tanggal Cetak</strong></td>
         <td className="text-end">{moment(suratDetails.tanggalCetak).format("DD MMMM YYYY")}</td>
       </tr>
       <tr>
         <td className="text-start"><strong>Kode Klasifikasi</strong></td>
         <td className="text-end">{suratDetails.kodeKlasifikasi}</td>
       </tr>
       <tr>
         <td className="text-start"><strong>Indeks Berkas</strong></td>
         <td className="text-end">{suratDetails.indeksBerkas}</td>
       </tr>
       <tr>
         <td className="text-start"><strong>Tanggal Surat</strong></td>
         <td className="text-end">{moment(suratDetails.tanggalSurat).format("DD MMMM YYYY")}</td>
       </tr>
       <tr>
         <td className="text-start"><strong>Diterima Tanggal</strong></td>
         <td className="text-end">{moment(suratDetails.tanggalDiterima).format("DD MMMM YYYY")}</td>
       </tr>
        {suratDetails.fileSurat && (
       <tr>
         <td className="text-start">File Surat</td>
         <td className="text-end">
                   <Link
                      onClick={() => handleOpenViewer(fullFilePath)}
                    >
                      <FontAwesomeIcon icon={faFilePdf} className="me-2" />
                    </Link>
                    </td>
       </tr>
        )}
       <tr>
         <td className="text-start"><strong>Keterangan</strong></td>
         <td className="text-end">{suratDetails.keterangan ? suratDetails.keterangan : '-'}</td>
       </tr>
       <tr>
         <td className="text-start"><strong>Nama Penerima</strong></td>
         <td className="text-end">{suratDetails.namaPenerima ? suratDetails.namaPenerima : '-'}</td>
       </tr>
       <tr>
         <td className="text-start"><strong>Nomor Telp Penerima</strong></td>
         <td className="text-end">{suratDetails.noTelpPenerima ? suratDetails.noTelpPenerima : '-'}</td>
       </tr>
   
     </tbody>
      </Table>
      
      </div>
       {isViewerOpen && (
                <DocumentViewer file={file} onClose={handleCloseViewer} />
            )}
     
       </Card.Body>
    </Card>
    
    </>
  );
};
