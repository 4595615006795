import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
  Spinner,
} from "@themesberg/react-bootstrap";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { BaseUrl } from "../routes/BaseUrl";
import { toast } from "react-toastify";
import Autosuggest from "react-autosuggest";

export const GeneralInfoForm = () => {
  const token = localStorage.getItem("token");
  const year  = localStorage.getItem("year");
  const Navigate = useNavigate();
  const [formData, setFormData] = useState({
    namaRole: "",
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
  
    const newErrors = {};
    if (!formData.namaRole)
      newErrors.namaRole = "Masukan Nama Jabatan";
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [roleoptions, setRoleOptions] = useState([]);
  
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get(BaseUrl + `/api/role/all`, {
          headers: {
            Authorization: `Bearer ${token}`, // Replace with your actual token
          },
        });
        setRoleOptions(response.data); // Assuming response.data is an array of options
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRoles();
  }, []);

    
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    try {
      const response = await axios.post(
        BaseUrl + `/api/role/store`,
        {
          nama: formData.namaRole,
          parent: formData.parent

        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add your token here
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        
        setFormData({
        namaRole: '', // Reset ke string kosong, bukan null
        });
        Navigate("/admin/users/role/create");
      } else {
        toast.error(response.data.message);
        Navigate("/admin/users/role/create");
        console.log("API is not working");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };


  const CreateUserRole = () => {
    return (
      <>
        <h5 className="mb-4">Tambah Jabatan</h5>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="namaRole">
              <Form.Label>Nama Jabatan</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Masukan Nama Jabatan"
                value={formData.namaRole}
                onChange={(e) =>
                  setFormData({ ...formData, namaRole: e.target.value })
                }
                isInvalid={!!errors.namaRole}
              />
              <Form.Control.Feedback type="invalid">
                {errors.namaRole}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            
          </Col>
        </Row>
        
        <div className="mt-3">
          <Button
            variant="primary"
            disabled={loading}
            onClick={handleFormSubmit}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Simpan"
            )}
          </Button>
        </div>
      </>
    );
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form onSubmit={handleFormSubmit}>{CreateUserRole()}</Form>
      </Card.Body>
    </Card>
  );
};
