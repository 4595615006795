import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome,faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Breadcrumb, Table, Card, Container, Button  } from "@themesberg/react-bootstrap";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { BaseUrl,SuratMasukUpload,SuratKeluarUpload } from "../../routes/BaseUrl";
import moment from "moment";
import DocumentViewer from '../../components/DocumentViewer';

export default () => {
  const { id } = useParams();
  console.log(id);
  const [disposisiDetails, setDisposisiDetails] = useState(null);
  const token = localStorage.getItem("token");
  
  const markDisposisiAsRead = async (disposisiId) => {
  try {
    const response = await axios.get(
      `${BaseUrl}/api/disposisi/markread/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Pass Bearer token if neede
        },
      }
    );

    if (response.data.status === 'success') {
      console.log('Disposisi marked as read');
    } else if (response.data.status === 'already_read') {
      console.log('Disposisi already read');
    }
  } catch (error) {
    console.error('Error marking disposisi as read:', error);
  }
};

  useEffect(() => {
    // Call the function to mark as read when the component mounts
    markDisposisiAsRead(id);
  }, []);
 
  useEffect(() => {
    const fetchDisposisi = async () => {
      try {
        const response = await axios.get(
          BaseUrl + `/api/disposisi/dispshow/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Replace with your actual token
            },
          }
        );
        setDisposisiDetails(response.data); // Assuming response.data is an array of options
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching disposisi:", error);
      }
    };

    fetchDisposisi();
  }, []);

  if (!disposisiDetails) {
    return <div>Loading...</div>;
  }
  
   

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/user/suratmasuk/list">Disposisi</Breadcrumb.Item>
            <Breadcrumb.Item active>{disposisiDetails.id}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Detail</h4>
        </div>
      </div>
      
      <Card border="light" className="bg-white shadow-sm">
      <Card.Body>
      
      <div className="table-responsive">

      <Table hover className="mb-1">
      <tbody>
       <tr>
        <td className="text-start"><strong>Perihal Surat</strong></td>
        <td className="text-end">{disposisiDetails.pengirim_name}</td>
       </tr>
       <tr>
         <td className="text-start"><strong>Asal Disposisi</strong></td>
         <td className="text-end">{disposisiDetails.surat_perihal}</td>
       </tr>
       <tr>
         <td className="text-start"><strong>Dikirim Pada</strong></td>
         <td className="text-end">{moment(disposisiDetails.created_at).format('DD MMMM YYYY, HH:mm ')}</td>
       </tr>
       <tr>
         <td className="text-start"><strong>Isi Disposisi</strong></td>
         <td className="text-end">{disposisiDetails.isi}</td>
       </tr>
       <tr>
         <td className="text-start"><strong>Sifat Disposisi</strong></td>
         <td className="text-end text-wrap text-break">{disposisiDetails.sifat}</td>
       </tr>
       <tr>
         <td className="text-start"><strong>Catatan</strong></td>
         <td className="text-end">{disposisiDetails.catatan}</td>
       </tr>
       
     </tbody>
      </Table>
      
      </div>
     
     
       </Card.Body>
    </Card>
    
    </>
  );
};
