import React, { useState,useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup } from '@themesberg/react-bootstrap';
import { SuratKeluarSearchTable } from "../../components/Tables";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [searchQuery, setSearchQuery] = useState(queryParams.get('query') || '');
    const navigate = useNavigate();

    const handleSearch = () => {
        if (searchQuery.trim()) {
            // Navigate to the results page with the query as a parameter
            navigate(`/admin/suratkeluar/search?query=${encodeURIComponent(searchQuery)}`);
        }else{
            navigate(`/admin/suratkeluar/list`);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };
    
    useEffect(() => {
        // This will update the searchQuery state whenever the query parameter changes
        const newQuery = queryParams.get('query');
        setSearchQuery(newQuery || '');
        // You can also trigger a data fetch or update here if necessary
    }, [location.search]); 

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                        <Breadcrumb.Item href="/admin/suratkeluar/list">Surat Keluar</Breadcrumb.Item>
                        <Breadcrumb.Item active>Pencarian Surat Keluar</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Pencarian Surat Keluar</h4>
                </div>
            </div>

            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0">
                    <div className="me-lg-3">
                        <Button as={Link} to="/admin/suratkeluar/create" variant="secondary" className="text-dark me-2">
                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                            <span>Tambah Surat Baru</span>
                        </Button>
                    </div>
                </Col>

                <Col xs={12} lg={3} className="px-0 mb-0">
                    <InputGroup>
                        <InputGroup.Text>
                            <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <Form.Control
                            type="text"
                            placeholder="Pencarian Surat"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onKeyPress={handleKeyPress}
                        />
                    </InputGroup>
                </Col>
            </div>

            {/* Pass searchQuery as a prop to SuratMasukSearchTable if needed */}
            <SuratKeluarSearchTable searchQuery={searchQuery} /> 
        </>
    );
};
