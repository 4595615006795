import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
  Spinner,
} from "@themesberg/react-bootstrap";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { BaseUrl } from "../routes/BaseUrl";
import { toast } from "react-toastify";
import Autosuggest from "react-autosuggest";

export const GeneralInfoForm = () => {
  const token = localStorage.getItem("token");
  const year  = localStorage.getItem("year");
  const Navigate = useNavigate();
  const [formData, setFormData] = useState({
    pemberi: "",
    penerima: "",
   
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
  
  const newErrors = {};
  
   if (!formData.pemberi)
   newErrors.pemberi = "Pilih Pejabat Pemberi";
   if (!formData.penerima)
   newErrors.penerima = "Pilih Pejabat Penerima";
  
     
   setErrors(newErrors);
   return Object.keys(newErrors).length === 0;
  };

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [roleoptions, setRoleOptions] = useState([]);
  
  
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await axios.get(BaseUrl + `/api/users/list`, {
          headers: {
            Authorization: `Bearer ${token}`, // Replace with your actual token
          },
        });
        setOptions(response.data); // Assuming response.data is an array of options
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchOptions();
  }, []);
  

    
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    console.log(formData); 
    
    setLoading(true);
    try {
      const response = await axios.post(
        BaseUrl + `/api/disposisi/accstore`,
        {
          pemberi: formData.pemberi,
          penerima: formData.penerima,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add your token here
            "Content-Type": "multipart/form-data",
          },
        }
      );
      //console.log(response.data);
      if (response.data.status === "success") {
        toast.success(response.data.message);
        Navigate("/admin/users/disposisi");
      } else {
        toast.error(response.data.message);
        Navigate("/admin/users/disposisi");
        console.log("API is not working");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
     
  };


  const CreateUserPeriode = () => {
    return (
      <>
        <h5 className="mb-4">Tambah Akses Disposisi</h5>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="nama">
              <Form.Label>Pejabat Pembuat</Form.Label>
              <Form.Control
                required
                as="select"
                value={formData.pemberi}
                onChange={(e) =>
                  setFormData({ ...formData, pemberi: e.target.value })
                }
                isInvalid={!!errors.pemberi}
              >
                <option value="">Pilih Akun</option>
                 {options.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.name}  
                    {option.periode.length > 0 
                    ? option.periode.map((periode) => (
                        <div key={periode.id}> [ {periode.role.nama} ]</div>
                    ))
                    : " - "}
                  </option>
                ))}
                </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.pemberi}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="role">
             <Form.Label>Pejabat Penerima</Form.Label>
              <Form.Control
              required
              as="select"
              multiple
              value={formData.penerima}
              onChange={(e) =>
              setFormData({ ...formData, penerima: Array.from(e.target.selectedOptions, option => option.value) })
              }
               isInvalid={!!errors.penerima}
              style={{ height: '200px' }}  // Menentukan tinggi dropdown
               >
               {options.map((option, index) => (
               <option key={index} value={option.id}>
                {option.name}  
                 {option.periode.length > 0 
                    ? option.periode.map((periode) => (
                        <div key={periode.id}> [ {periode.role.nama} ]</div>
                    ))
                   : " - "}
                </option>
                ))}  
               </Form.Control>
               <Form.Control.Feedback type="invalid">
               {errors.penerima}
                </Form.Control.Feedback>
              </Form.Group>
          </Col>
        </Row>
               
        
        <div className="mt-3">
          <Button
            variant="primary"
            disabled={loading}
            onClick={handleFormSubmit}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Simpan"
            )}
          </Button>
        </div>
      </>
    );
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form onSubmit={handleFormSubmit}>{CreateUserPeriode()}</Form>
      </Card.Body>
    </Card>
  );
};
