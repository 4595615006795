import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from '@themesberg/react-bootstrap';
import { useParams } from "react-router-dom";
import { DisposisiTrackTable } from "../../components/Tables";
import { Link,useNavigate } from "react-router-dom";
import { BaseUrl,UploadUrl } from "../../routes/BaseUrl";


export default () => {
  const { id } = useParams();
  


  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item href={`/admin/suratmasuk/view/${id}`}>Surat Masuk</Breadcrumb.Item>
            <Breadcrumb.Item active>Tracking Disposisi </Breadcrumb.Item>
          </Breadcrumb>
          <h4>Tracking Disposisi #{id}</h4>
        </div>
        
      </div>

      
    
      <DisposisiTrackTable /> 
      
    </>
  );
};
