import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
  Spinner,
} from "@themesberg/react-bootstrap";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { BaseUrl } from "../routes/BaseUrl";
import { toast } from "react-toastify";
import Autosuggest from "react-autosuggest";

export const GeneralInfoForm = () => {
  const token = localStorage.getItem("token");
  const year  = localStorage.getItem("year");
  const Navigate = useNavigate();
  const [formData, setFormData] = useState({
    nama: "",
    email: "",
    password: "",
    hakAkses: "",
    status: "",
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
  
  const newErrors = {};
  
   if (!formData.nama)
   newErrors.nama = "Masukan Nama Akun";
   if (!formData.email)
   newErrors.email = "Masukan Email Akun";
   if (!formData.password)
   newErrors.password = "Masukan Password Akun";
    if (!formData.cpassword)
   newErrors.cpassword = "Masukan Konfirmasi Password ";
    if (!formData.cpassword)
   newErrors.cpassword = "Masukan Konfirmasi Password ";
   
    if (!formData.hakAkses)
   newErrors.hakAkses = "Pilih Hak Akses";
    if (!formData.status)
   newErrors.status = "Pilih Status Akun";
    if (formData.password !== formData.cpassword)
   newErrors.password = "Password dan konfirmasi harus sama";
   
   setErrors(newErrors);
   return Object.keys(newErrors).length === 0;
  };

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await axios.get(BaseUrl + `/api/role/all`, {
          headers: {
            Authorization: `Bearer ${token}`, // Replace with your actual token
          },
        });
        setOptions(response.data); // Assuming response.data is an array of options
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching options:", error);
        setErrors({ tujuanSurat: "Failed to load options" });
      }
    };

    fetchOptions();
  }, []);
  
  

    
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    console.log(formData); 
    setLoading(true);
    try {
      const response = await axios.post(
        BaseUrl + `/api/users/store`,
        {
          nama: formData.nama,
          email: formData.email,
          password: formData.password,
          hakAkses: formData.hakAkses,
          status:formData.status
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add your token here
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      if (response.data.status === "success") {
        toast.success(response.data.message);
        
        setFormData({
        nama: "",
        email: "",
        password: "",
        cpassword: "",
        status: "",
        hakAkses: "",
        // tambahkan semua field formData lainnya ke null
       });
       Navigate("/admin/users/account/create");
      } else {
        toast.error(response.data.message);
        Navigate("/admin/users/account/create");
        console.log("API is not working");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };


  const CreateUserRole = () => {
    return (
      <>
        <h5 className="mb-4">Tambah Akun User</h5>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="nama">
              <Form.Label>Nama Akun</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Masukan Nama Akun"
                value={formData.nama}
                onChange={(e) =>
                  setFormData({ ...formData, nama: e.target.value })
                }
                isInvalid={!!errors.nama}
              />
              <Form.Control.Feedback type="invalid">
                {errors.nama}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
             <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Masukan Email "
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
         <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Masukan Password"
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                isInvalid={!!errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
             <Form.Group id="cpassword">
              <Form.Label>Konfirmasi Password</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Masukan Konfirmasi Password"
                value={formData.cpassword}
                onChange={(e) =>
                  setFormData({ ...formData, cpassword: e.target.value })
                }
                isInvalid={!!errors.cpassword}
              />
              <Form.Control.Feedback type="invalid">
                {errors.cpassword}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
         
          <Col md={6} className="mb-3"> 
             <Form.Group id="hakAkses">
              <Form.Label>Hak Akses</Form.Label>
              <Form.Control
                required
                as="select"
                value={formData.hakAkses}
                onChange={(e) =>
                  setFormData({ ...formData, hakAkses: e.target.value })
                }
                isInvalid={!!errors.hakAkses}
              >
                <option value="">Pilih Hak Akses</option>
                <option value="admin">Administrator</option>
                <option value="user">User</option>    
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.hakAkses}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3"> 
             <Form.Group id="hakAkses">
              <Form.Label>Status</Form.Label>
              <Form.Control
                required
                as="select"
                value={formData.status}
                onChange={(e) =>
                  setFormData({ ...formData, status: e.target.value })
                }
                isInvalid={!!errors.status}
              >
                <option value="">Pilih Status</option>
                <option value="aktif">Aktif</option>
                <option value="non-aktif">Non Aktif</option>    
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.status}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
           
        </Row>
         <Row>
         
          
         
        </Row>
        
        <div className="mt-3">
          <Button
            variant="primary"
            disabled={loading}
            onClick={handleFormSubmit}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Simpan"
            )}
          </Button>
        </div>
      </>
    );
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form onSubmit={handleFormSubmit}>{CreateUserRole()}</Form>
      </Card.Body>
    </Card>
  );
};
