import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxOpen,
  faCartArrowDown,
  faChartPie,
  faChevronDown,
  faClipboard,
  faCommentDots,
  faFileAlt,
  faPlus,
  faRocket,
  faStore,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Button, Dropdown, Breadcrumb } from "@themesberg/react-bootstrap";
import { GeneralInfoForm } from "../../components/CreateSuratMasuk";


export default () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item href="/admin/suratmasuk/list">Surat Masuk</Breadcrumb.Item>
            <Breadcrumb.Item active>Tambah Surat Masuk</Breadcrumb.Item>
          </Breadcrumb>
         </div>
        
      </div>
   
      <Row className="mb-5">
        <Col xs={12} xl={12}>
          <GeneralInfoForm />
        </Col>
      </Row>
    </>
  );
};

