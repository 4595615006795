import React, { useState, useEffect } from "react";
import {Navigate, useRoutes, useLocation } from 'react-router-dom';
import RoleGuard from '../guard/RoleGuard';
import AuthGuard from '../guard/AuthGuard';
import Signin from '../auth/Signin';
import NotFoundPage from '../pages/NotFound';

//import admin pages
import AdminDashboard from '../apps/admin/Dashboard';
import AdminSuratMasukList from '../apps/admin/SuratMasukList';
import AdminSuratMasukCreate from '../apps/admin/SuratMasukCreate';
import AdminSuratMasukEdit from '../apps/admin/SuratMasukEdit';
import AdminSuratMasukKendali from '../apps/admin/SuratMasukKendali';
import AdminSuratMasukTerima from '../apps/admin/SuratMasukTerima';
import AdminSuratMasukSearch from '../apps/admin/SuratMasukSearch';
import AdminSuratMasukView from '../apps/admin/SuratMasukView';
import AdminDisposisiTrack from '../apps/admin/DisposisiTrack';

import AdminSuratKeluarList from '../apps/admin/SuratKeluarList';
import AdminSuratKeluarCreate from '../apps/admin/SuratKeluarCreate';
import AdminSuratKeluarEdit from '../apps/admin/SuratKeluarEdit';
import AdminSuratKeluarView from '../apps/admin/SuratKeluarView';
import AdminSuratKeluarKendali from '../apps/admin/SuratKeluarKendali';
import AdminSuratKeluarTerima from '../apps/admin/SuratKeluarTerima';
import AdminSuratKeluarSearch from '../apps/admin/SuratKeluarSearch';

import AdminRoleList from '../apps/admin/RoleList';
import AdminRoleEdit from '../apps/admin/RoleEdit';
import AdminRoleCreate from '../apps/admin/RoleCreate';

import AdminAccountList from '../apps/admin/AccountList';
import AdminAccountCreate from '../apps/admin/AccountCreate';
import AdminAccountEdit from '../apps/admin/AccountEdit';

import AdminPeriodeList from '../apps/admin/PeriodeList';
import AdminPeriodeCreate from '../apps/admin/PeriodeCreate';
import AdminPeriodeEdit from '../apps/admin/PeriodeEdit';

import AdminDispAccList from '../apps/admin/DispAccList';
import AdminDispAccCreate from '../apps/admin/DispAccCreate';
import AdminDispAccEdit from '../apps/admin/DispAccEdit';


import AdminKlasifikasiList from '../apps/admin/KlasifikasiList';
import AdminKlasifikasiSearch from '../apps/admin/KlasifikasiSearch';

//import user pages

import UserSuratMasukList from '../apps/user/SuratMasukList';
import UserSuratMasukView from '../apps/user/SuratMasukView';
import UserDisposisiList from '../apps/user/DisposisiList';
import UserDisposisiCreate from '../apps/user/DisposisiCreate';
import UserDisposisiEdit from '../apps/user/DisposisiEdit';
import UserDisposisiInList from '../apps/user/DisposisiInList';
import UserSuratMasukInbox from '../apps/user/SuratMasukInbox';
import UserDisposisiView from '../apps/user/DisposisiView';
import UserDisposisiTrack from '../apps/user/DisposisiTrack';
import UserDisposisiPrint from '../apps/user/DisposisiPrint';


// Import components
import Sidebar from '../layout/Sidebar';
import UserSidebar from '../layout/UserSidebar';
import Navbar from '../layout/Navbar';
import Footer from '../layout/Footer';
import Preloader from '../layout/Preloader';


// LoaderWrapper Component
const LoaderWrapper = ({ children }) => {
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Preloader show={!loaded} />
      {loaded && children}
    </>
  );
};

// SidebarWrapper Component
const SidebarWrapper = ({ children }) => {
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') !== 'false';
  };

  const [showSettings, setShowSettings] = React.useState(
    localStorageIsSettingsVisible()
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  };

  return (
    <>
      <Sidebar />
      <main className="content">
        <Navbar />
        {loaded && children}
        <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
      </main>
    </>
  );
};

const UserSidebarWrapper = ({ children }) => {
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') !== 'false';
  };

  const [showSettings, setShowSettings] = React.useState(
    localStorageIsSettingsVisible()
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  };

  return (
    <>
      <UserSidebar />
      <main className="content">
        <Navbar />
        {loaded && children}
        <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
      </main>
    </>
  );
};

export default function Router() {


    const location = useLocation();

    const loadStyles = async () => {
    const excludedPatterns = [
        /^\/admin\/suratmasuk\/kendali\/\d+$/,    // Matches '/admin/suratmasuk/kendali/{number}'
        /^\/admin\/suratmasuk\/terima\/\d+$/,     // Matches '/admin/suratmasuk/terima/{number}'
        /^\/admin\/suratkeluar\/kendali\/\d+$/,   // Matches '/admin/suratkeluar/kendali/{number}'
        /^\/admin\/suratkeluar\/terima\/\d+$/,    // Matches '/admin/suratkeluar/terima/{number}'
        /^\/user\/disposisi\/cetak\/\d+$/         // Matches '/user/disposisi/cetak/{number}'
        
        
    ];

    // Check if the current pathname matches any excluded pattern
    const isExcluded = excludedPatterns.some(pattern => pattern.test(location.pathname));

    if (!isExcluded) {
        await import('../scss/volt.scss'); // Load default styles
      }
    };

    // Load styles when the pathname changes
    useEffect(() => {
        loadStyles();
    }, [location.pathname]);

    
    return useRoutes([
        {
            path: '/',
            element:  
            <LoaderWrapper>
             <Signin />
            </LoaderWrapper>,
        },
        {
            path: 'signin',
            element:  
            <LoaderWrapper>
             <Signin />
            </LoaderWrapper>,
        },
        {
            path: 'admin',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminDashboard />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'admin/dashboard',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminDashboard />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'admin/suratmasuk/list',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminSuratMasukList />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'admin/suratmasuk/create',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminSuratMasukCreate />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'admin/suratmasuk/view/:id',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminSuratMasukView />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'admin/suratmasuk/edit/:id',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminSuratMasukEdit />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'admin/suratmasuk/kendali/:id',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <AdminSuratMasukKendali />
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'admin/suratmasuk/disposisi/:id',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminDisposisiTrack />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'admin/suratmasuk/terima/:id',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <AdminSuratMasukTerima />
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'admin/suratmasuk/search',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminSuratMasukSearch />
                      </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
         {
            path: 'admin/suratkeluar/list',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminSuratKeluarList />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
         {
            path: 'admin/suratkeluar/create',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminSuratKeluarCreate />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'admin/suratkeluar/edit/:id',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminSuratKeluarEdit />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'admin/suratkeluar/view/:id',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminSuratKeluarView />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'admin/suratkeluar/kendali/:id',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <AdminSuratKeluarKendali />
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'admin/suratkeluar/terima/:id',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <AdminSuratKeluarTerima />
                </RoleGuard>
            </AuthGuard>,
        },
         {
            path: 'admin/suratkeluar/search',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminSuratKeluarSearch />
                      </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'admin/klasifikasi/list',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminKlasifikasiList />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'admin/klasifikasi/search',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminKlasifikasiSearch />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'admin/users/role',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminRoleList />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
         {
            path: 'admin/users/role/create',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminRoleCreate />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'admin/users/role/edit/:id',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminRoleEdit />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'admin/users/account',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminAccountList />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
         {
            path: 'admin/users/account/create',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminAccountCreate />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'admin/users/account/edit/:id',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminAccountEdit />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'admin/users/periode',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminPeriodeList />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'admin/users/disposisi',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminDispAccList />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'admin/users/disposisi/access',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminDispAccList />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'admin/users/disposisi/create',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminDispAccCreate />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'admin/users/disposisi/edit/:id',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminDispAccEdit />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
         {
            path: 'admin/users/periode/create',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminPeriodeCreate />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
         {
            path: 'admin/users/periode/edit/:id',
            element: <AuthGuard>
                <RoleGuard ROLE='admin'>
                     <SidebarWrapper >
                     <AdminPeriodeEdit />
                     </SidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
         {
            path: 'user/suratmasuk',
            element: <AuthGuard>
                <RoleGuard ROLE='user'>
                     <UserSidebarWrapper >
                     <UserSuratMasukList />
                     </UserSidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
         {
            path: 'user/suratmasuk/list',
            element: <AuthGuard>
                <RoleGuard ROLE='user'>
                     <UserSidebarWrapper >
                     <UserSuratMasukList />
                     </UserSidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'user/suratmasuk/inbox',
            element: <AuthGuard>
                <RoleGuard ROLE='user'>
                     <UserSidebarWrapper >
                     <UserSuratMasukInbox />
                     </UserSidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
         {
            path: 'user/suratmasuk/view/:id',
            element: <AuthGuard>
                <RoleGuard ROLE='user'>
                     <UserSidebarWrapper >
                     <UserSuratMasukView />
                     </UserSidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'user/disposisi/keluar/:id',
            element: <AuthGuard>
                <RoleGuard ROLE='user'>
                     <UserSidebarWrapper >
                     <UserDisposisiList/>
                     </UserSidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'user/disposisi/masuk/:id',
            element: <AuthGuard>
                <RoleGuard ROLE='user'>
                     <UserSidebarWrapper >
                     <UserDisposisiInList />
                     </UserSidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'user/disposisi/cetak/:id',
            element: <AuthGuard>
                <RoleGuard ROLE='user'>
                     <UserDisposisiPrint />
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'user/disposisi/track/:id',
            element: <AuthGuard>
                <RoleGuard ROLE='user'>
                     <UserSidebarWrapper >
                     <UserDisposisiTrack />
                     </UserSidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'user/disposisi/create/:id',
            element: <AuthGuard>
                <RoleGuard ROLE='user'>
                     <UserSidebarWrapper >
                      <UserDisposisiCreate />
                     </UserSidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'user/disposisi/edit/:id/:suratId',
            element: <AuthGuard>
                <RoleGuard ROLE='user'>
                     <UserSidebarWrapper >
                      <UserDisposisiEdit />
                     </UserSidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: 'user/disposisi/view/:id/:suratId',
            element: <AuthGuard>
                <RoleGuard ROLE='user'>
                     <UserSidebarWrapper >
                      <UserDisposisiView />
                     </UserSidebarWrapper >
                </RoleGuard>
            </AuthGuard>,
        },
        {
            path: '*',
            element:  
            <NotFoundPage />,
        },
        
    ]);
}
