import React, { useState } from "react";
import SimpleBar from "simplebar-react";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MdOutlineOutbox } from "react-icons/md";

import {
  faBook,
  faBoxOpen,
  faChartPie,
  faFlask,
  faFileAlt,
  faNotesMedical,
  faSignOutAlt,
  faTable,
  faTimes,
  faUsers,
  faMortarPestle,
  faMapPin,
  faInbox,
  faRocket,
  faCalendarMinus,
  faWrench
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  Badge,
  Image,
  Button,
  Dropdown,
  Accordion,
  Navbar,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import Cirebonkab from "../assets/img/cirebonkab.svg";
import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  const onCollapse = () => setShow(!show);

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button
            as={Nav.Link}
            className="d-flex justify-content-between align-items-center"
          >
            <span>
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">{children}</Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
  const {
    title,
    link,
    external,
    target,
    icon,
    image,
    badgeText,
    badgeBg = "secondary",
    badgeColor = "primary",
    style,
    active, // New prop
  } = props;

  // Check if the pathname includes the link or if the active prop is true
  const navItemClassName =
    pathname.includes(link) || active ? "active" : "";
  const classNames = badgeText
    ? "d-flex justify-content-start align-items-center justify-content-between"
    : "";
  const linkProps = external ? { href: link } : { as: Link, to: link };

  return (
    <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
      <Nav.Link {...linkProps} target={target} className={classNames}>
        <span className="d-flex align-items-center">
          {icon ? (
            <span className="sidebar-icon">
              <FontAwesomeIcon icon={icon} />{" "}
            </span>
          ) : null}
          {image ? (
            <Image
              src={image}
              width={40}
              height={40}
              className="sidebar-icon svg-icon"
            />
          ) : null}

          <span className="sidebar-text" style={style}>
            {title}
          </span>
        </span>
        {badgeText ? (
          <Badge
            pill
            bg={badgeBg}
            text={badgeColor}
            className="badge-md notification-count ms-2"
          >
            {badgeText}
          </Badge>
        ) : null}
      </Nav.Link>
    </Nav.Item>
  );
};


  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <Navbar.Brand
          className="me-lg-5"
          as={Link}
          to="/admin/dashboard"
        >
          <Image src={Cirebonkab} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image
                    src={ProfilePicture}
                    className="card-img-top rounded-circle border-white"
                  />
                </div>
                <div className="d-block">
                  <h6>Hi, Mohit</h6>
                  <Button
                    as={Link}
                    variant="secondary"
                    size="xs"
                    to="/signin"
                    className="text-dark"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{" "}
                    Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <NavItem
                title="MSM"
                // link={Routes.DashboardOverview.path}
                image={Cirebonkab}
                style={{ fontSize: "1.5rem" }}
              />

              <NavItem
                title="Dashboard"
                link="/admin/dashboard"
                icon={faChartPie}
              />
              
              <NavItem
                title="Surat Masuk"
                icon={faInbox}
                link="/admin/suratmasuk/list"
                active={pathname.includes("/admin/suratmasuk")}
              />
              
               <NavItem
                title="Surat Keluar"
                icon={faSignOutAlt}
                link="/admin/suratkeluar/list"
                active={pathname.includes("/admin/suratkeluar")}
              />
              
              <NavItem
                title="Klasifikasi"
                icon={faBook}
                link="/admin/klasifikasi/list"
                active={pathname.includes("/admin/klasifikasi")}
              />
              
              
               <CollapsableNavItem
                eventKey="documentation/"
                title="Laporan"
                icon={faCalendarMinus}
              >
                <NavItem title="Surat Masuk" link="/admin/laporan/suratmasuk" />
                <NavItem title="Surat Keluar" link="/admin/laporan/suratkeluar" />
              </CollapsableNavItem>
              
                           
               <CollapsableNavItem
                eventKey="users/"
                title="Pengguna"
                icon={faUsers}
              >
                <NavItem title="Jabatan" link="/admin/users/role" />
                <NavItem title="Akun" link="/admin/users/account" />
                <NavItem title="Periode" link="/admin/users/periode" />
                <NavItem title="Disposisi" link="/admin/users/disposisi" />
              </CollapsableNavItem>
              
              
               <NavItem
                title="Pengaturan"
                icon={faWrench}
                link="/admin/settings"
              />
              
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
