import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisH,
  faEye,
  faTrashAlt,
  faClock,
  faCheckCircle,
  faEnvelope,
  faFilePdf,
  faPrint,
  faArrowUp,
  faArrowDown,
  faPen,
  faShare,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  Col,
  Card,
  Button,
  Table,
  Dropdown,
  Pagination,
  ButtonGroup,
  Spinner,
  Badge,
} from "@themesberg/react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import {
  BaseUrl,
  SuratMasukUpload,
  SuratKeluarUpload,
} from "../routes/BaseUrl";
import moment from "moment";
import CommonDeleteModal from "./CommonDeleteModal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "moment/locale/id";
import DocumentViewer from "./DocumentViewer";
import ContentLoader from "react-content-loader";

export const SuratMasukTable = () => {
  moment.locale("id");
  const [suratmasuk, setSuratMasuk] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSuratId, setSelectedSuratId] = useState(null);
  const itemsPerPage = 10;
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [file, setFile] = useState(null);

  const handleOpenViewer = (file) => {
    setFile(file);
    setIsViewerOpen(true);
  };

  const handleCloseViewer = () => {
    setIsViewerOpen(false);
    setFile(null);
  };

  const fetchSuratMasuk = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const year = localStorage.getItem("year");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/suratmasuk/list?year=${year}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = response.data;
      // Map through data to include user.name
      const suratMasukWithUser = data.map((item) => ({
        ...item,
        userName: item.user?.name || "", // Ambil userName, default jika tidak ada
        roleName: item.role?.nama || "", // Ambil roleName, default jika tidak ada
      }));

      setSuratMasuk(suratMasukWithUser);
      console.log(suratMasukWithUser);
      console.log(data);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching surat masuk:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSuratMasuk();
  }, [currentPage]);

  const handleDeleteClick = (suratId) => {
    setSelectedSuratId(suratId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteSuccess = async () => {
    setCurrentPage(1);
    await fetchSuratMasuk();
  };

  const maxPagesToShow = 10;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ surat, index }) => {
    const {
      id,
      no_agenda,
      dari,
      isi_ringkas,
      no_surat,
      tgl_surat,
      sifat,
      kode,
      file,
      userName,
      roleName,
    } = surat;
    const formattedDate = moment(tgl_surat).format("DD MMMM YYYY");
    const fullFilePath = `${SuratMasukUpload}${file}`;

    return (
      <Card border="bottom" className="hover-state rounded-0 py-3">
        <Card.Body className="d-sm-flex align-items-md-center flex-wrap flex-lg-nowrap py-0 ps-sm-0">
          <Col
            xs="auto"
            lg={1}
            className="text-start text-sm-center ps-0 ps-sm-4 mb-2 mb-sm-0"
          >
            <b>{no_agenda}</b>
            <br />
            {file && (
              <Button
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  margin: 0,
                }}
                onClick={() => handleOpenViewer(fullFilePath)}
              >
                <FontAwesomeIcon
                  icon={faFilePdf}
                  className="me-2"
                  style={{ color: "black" }}
                />
              </Button>
            )}
          </Col>
          <Col xs={10} lg={8} className="d-block px-0 mb-4 mb-md-0">
            <div className="mb-2">
              <h5>
                {" "}
                <FontAwesomeIcon icon={faArrowDown} className="me-2" /> {dari}
              </h5>
              <div className="d-block d-sm-flex">
                <div>
                  <h6 className="fw-normal mb-3 mb-sm-0">
                    {no_surat} <b>{formattedDate}</b>
                  </h6>
                </div>
                <div className="ms-sm-3">
                  <Badge bg="info" className="super-badge badge-lg">
                    {userName} {roleName && `[${roleName}]`}
                  </Badge>
                </div>
                <div className="ms-sm-3">
                  <Badge bg="danger" className="super-badge badge-lg">
                    {sifat} 
                  </Badge>
                </div>
              </div>
            </div>

            <div>
              <div className="fw-bold text-dark">
                <span className={`fw-normal text-gray`}>{isi_ringkas}</span>
              </div>
            </div>
          </Col>

          <Col
            xs={10}
            sm={2}
            lg={2}
            xl={2}
            className="d-none d-lg-block d-xl-inline-flex align-items-center ms-lg-auto text-end justify-content-end px-md-0"
          >
            <Dropdown className="ms-3">
  <Dropdown.Toggle
    as={Button}
    variant="link"
    className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
    aria-haspopup="true"
    aria-expanded="false"
  >
    <span className="icon icon-sm icon-dark">
      <FontAwesomeIcon icon={faEllipsisH} />
    </span>
  </Dropdown.Toggle>
  {/* Remove dropdown-menu-right to improve mobile responsiveness */}
  <Dropdown.Menu className="dropdown-menu-end"> 
    <Dropdown.Item as={Link} to={`/admin/suratmasuk/view/${id}`} className="ms-0">
      <FontAwesomeIcon icon={faEye} className="me-2" /> Lihat Detail
    </Dropdown.Item>
    <Dropdown.Item as={Link} to={`/admin/suratmasuk/edit/${id}`} className="ms-0">
      <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
    </Dropdown.Item>
    <Dropdown.Item className="text-danger ms-0" onClick={() => handleDeleteClick(id)}>
      <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Delete
    </Dropdown.Item>
    <Dropdown.Item as={Link} to={`/admin/suratmasuk/disposisi/${id}`} className="ms-0">
      <FontAwesomeIcon icon={faSearch} className="me-2" /> Lacak Disposisi
    </Dropdown.Item>
    <Dropdown.Item as={Link} to={`/admin/suratmasuk/kendali/${id}`} className="ms-0">
      <FontAwesomeIcon icon={faPrint} className="me-2" /> Cetak Surat Kendali
    </Dropdown.Item>
    <Dropdown.Item as={Link} to={`/admin/suratmasuk/terima/${id}`} className="ms-0">
      <FontAwesomeIcon icon={faPrint} className="me-2" /> Cetak Surat Tanda Terima
    </Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>

          </Col>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton count={7} height={35} />
      ) : (
        <Card className="table-wrapper table-responsive shadow-sm">
          {suratmasuk.length === 0 ? (
            <div className="text-center pt-3">Tidak ada data</div>
          ) : (
            <div>
              {suratmasuk.map((surat, index) => (
                <TableRow key={index} surat={surat} index={index} />
              ))}
            </div>
          )}
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
            <Nav>
              <Pagination className="mb-2 mb-lg-0">
                <Pagination.Prev
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </Pagination.Prev>

                {paginationRange.map((page) => (
                  <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => setCurrentPage(page)}
                  >
                    {page}
                  </Pagination.Item>
                ))}

                <Pagination.Next
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </Pagination.Next>
              </Pagination>
            </Nav>
            <small className="fw-bold">
              Showing <b>{suratmasuk.length}</b> out of <b>{totalRecords}</b>{" "}
              entries
            </small>
          </Card.Footer>

          {/* Delete modal */}
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedSuratId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/suratmasuk/delete`}
            />
          )}

          {isViewerOpen && (
            <DocumentViewer file={file} onClose={handleCloseViewer} />
          )}
        </Card>
      )}
    </>
  );
};

export const UserSuratMasukTable = () => {
  moment.locale("id");
  const [suratmasuk, setSuratMasuk] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSuratId, setSelectedSuratId] = useState(null);
  const itemsPerPage = 10;
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [disposisiStatus, setDisposisiStatus] = useState({});

  const handleOpenViewer = (file) => {
    setFile(file);
    setIsViewerOpen(true);
  };

  const handleCloseViewer = () => {
    setIsViewerOpen(false);
    setFile(null);
  };

  const fetchSuratMasuk = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const year = localStorage.getItem("year");
    const userId = localStorage.getItem("userId");

    try {
      const response = await axios.get(
        `${BaseUrl}/api/user/suratmasuk/list?user=${userId}&year=${year}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = response.data;
      // Map through data to include user.name
      const suratMasukWithUser = data.map((item) => ({
        ...item,
        userName: item.user?.name || "Unknown", // Add userName field, default to "Unknown" if not present
      }));

      setSuratMasuk(suratMasukWithUser);
      console.log(suratMasukWithUser);
      console.log(data);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching surat masuk:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSuratMasuk();
  }, [currentPage]);

  const handleDeleteClick = (suratId) => {
    setSelectedSuratId(suratId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteSuccess = async () => {
    setCurrentPage(1);
    await fetchSuratMasuk();
  };

  const maxPagesToShow = 10;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const checkDisposisi = async (suratId) => {
    const userId = localStorage.getItem("userId");
    try {
      const token = localStorage.getItem("token"); // Assuming you store the token in localStorage
      const response = await axios.get(
        `${BaseUrl}/api/disposisi/check/${suratId}/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add Bearer token to the headers
          },
        }
      );
      return response.data.hasDisposisi; // Assuming response API contains boolean `hasDisposisi`
    } catch (error) {
      console.error("Error checking disposisi", error);
      return false;
    }
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ surat, index }) => {
    const [hasDisposisi, setHasDisposisi] = useState(false);

    useEffect(() => {
      const fetchDisposisiStatus = async () => {
        const status = await checkDisposisi(surat.id);
        setHasDisposisi(status);
      };

      fetchDisposisiStatus();
    }, [surat.id]);
    const {
      id,
      no_agenda,
      dari,
      isi_ringkas,
      no_surat,
      tgl_surat,
      sifat,
      kode,
      file,
      userName,
      nama_role,
    } = surat;
    const formattedDate = moment(tgl_surat).format("DD MMMM YYYY");
    const fullFilePath = `${SuratMasukUpload}${file}`;

    return (
      <Card border="bottom" className="hover-state rounded-0 py-3">
        <Card.Body className="d-sm-flex align-items-md-center flex-wrap flex-lg-nowrap py-0 ps-sm-0">
          <Col
            xs="auto"
            lg={1}
            className="text-start text-sm-center ps-0 ps-sm-4 mb-2 mb-sm-0"
          >
            <b>{no_agenda}</b>
            <br />
            {file && (
              <Button
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  margin: 0,
                }}
                onClick={() => handleOpenViewer(fullFilePath)}
              >
                <FontAwesomeIcon
                  icon={faFilePdf}
                  className="me-2"
                  title="Lihat Surat"
                  style={{ color: "black" }}
                />
              </Button>
            )}
          </Col>
          <Col xs={10} lg={8} className="d-block px-0 mb-4 mb-md-0">
            <div className="mb-2">
              <h5>
                {" "}
                <FontAwesomeIcon icon={faArrowDown} className="me-2" /> {dari}
              </h5>
              <div className="d-block d-sm-flex">
                <div>
                  <h6 className="fw-normal mb-3 mb-sm-0">
                    {no_surat} <b>{formattedDate}</b>
                  </h6>
                </div>
                <div className="ms-sm-3">
                  <Badge bg="danger" className="super-badge badge-lg">
                    {sifat}
                  </Badge>
                   
                  
                </div>
                 {hasDisposisi ? ( 
                    <div className="ms-sm-3">
                     <FontAwesomeIcon icon={faShare}  title="Disposisi Keluar" className="me-2" />
                    </div>
                  ) : ( null ) }
              </div>
            </div>

            <div>
              <div className="fw-bold text-dark">
                <span className={`fw-normal text-gray`}>{isi_ringkas}</span>
              </div>
            </div>
          </Col>

          <Col
            xs={10}
            sm={2}
            lg={2}
            xl={2}
            className="d-none d-lg-block d-xl-inline-flex align-items-center ms-lg-auto text-end justify-content-end px-md-0"
          >
            <Dropdown className="ms-3">
              <Dropdown.Toggle
                as={Button}
                variant="link"
                className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="icon icon-sm icon-dark">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-right">
                <Dropdown.Item
                  as={Link}
                  to={`/user/suratmasuk/view/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEye} className="me-2" /> Lihat Detail
                </Dropdown.Item>
                
                <Dropdown.Item
                  as={Link}
                  to={`/user/disposisi/keluar/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEdit} className="me-2" /> Disposisi
                  Keluar
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/user/disposisi/masuk/${id}`}
                  className="ms-0"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faPen} className="me-2" /> Disposisi
                  Masuk
                </Dropdown.Item>
                 <Dropdown.Item
                  as={Link}
                  to={`/user/disposisi/track/${id}`}
                  className="ms-0"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faSearch} className="me-2" /> Lacak Disposisi
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/user/disposisi/cetak/${id}`}
                  className="ms-0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faPrint} className="me-2" /> Cetak
                  Disposisi
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton count={7} height={35} />
      ) : (
        <Card className="table-wrapper table-responsive shadow-sm">
          {suratmasuk.length === 0 ? (
            <div className="text-center pt-3">Tidak ada data</div>
          ) : (
            <div>
              {suratmasuk.map((surat, index) => (
                <TableRow key={surat.id} surat={surat} index={index} />
              ))}
            </div>
          )}
           {suratmasuk.length > 0 ? (
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
            <Nav>
              <Pagination className="mb-2 mb-lg-0">
                <Pagination.Prev
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </Pagination.Prev>

                {paginationRange.map((page) => (
                  <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => setCurrentPage(page)}
                  >
                    {page}
                  </Pagination.Item>
                ))}

                <Pagination.Next
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </Pagination.Next>
              </Pagination>
            </Nav>
            <small className="fw-bold">
              Showing <b>{suratmasuk.length}</b> out of <b>{totalRecords}</b>{" "}
              entries
            </small>
          </Card.Footer>
          
          ) : ( null ) }

          {/* Delete modal */}
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedSuratId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/user/suratmasuk/delete`}
            />
          )}

          {isViewerOpen && (
            <DocumentViewer file={file} onClose={handleCloseViewer} />
          )}
        </Card>
      )}
    </>
  );
};

export const UserInboxTable = () => {
  moment.locale("id");
  const [suratmasuk, setSuratMasuk] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSuratId, setSelectedSuratId] = useState(null);
  const itemsPerPage = 10;
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [disposisiStatus, setDisposisiStatus] = useState({});

  const handleOpenViewer = (file) => {
    setFile(file);
    setIsViewerOpen(true);
  };

  const handleCloseViewer = () => {
    setIsViewerOpen(false);
    setFile(null);
  };

  const fetchSuratMasuk = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const year = localStorage.getItem("year");
    const userId = localStorage.getItem("userId");

    try {
      const response = await axios.get(
        `${BaseUrl}/api/user/suratmasuk/inbox?user=${userId}&year=${year}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = response.data;
      // Map through data to include user.name
      const suratMasukWithUser = data.map((item) => ({
        ...item,
        userName: item.user?.name || "Unknown", // Add userName field, default to "Unknown" if not present
      }));

      setSuratMasuk(suratMasukWithUser);
      console.log(suratMasukWithUser);
      console.log(data);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching surat masuk:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSuratMasuk();
  }, [currentPage]);

  const handleDeleteClick = (suratId) => {
    setSelectedSuratId(suratId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteSuccess = async () => {
    setCurrentPage(1);
    await fetchSuratMasuk();
  };

  const maxPagesToShow = 10;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const checkDisposisi = async (suratId) => {
    const userId = localStorage.getItem("userId");
    try {
      const token = localStorage.getItem("token"); // Assuming you store the token in localStorage
      const response = await axios.get(
        `${BaseUrl}/api/disposisi/check/${suratId}/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add Bearer token to the headers
          },
        }
      );
      return response.data.hasDisposisi; // Assuming response API contains boolean `hasDisposisi`
    } catch (error) {
      console.error("Error checking disposisi", error);
      return false;
    }
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ surat, index }) => {
    const [hasDisposisi, setHasDisposisi] = useState(false);

    useEffect(() => {
      const fetchDisposisiStatus = async () => {
        const status = await checkDisposisi(surat.id);
        setHasDisposisi(status);
      };

      fetchDisposisiStatus();
    }, [surat.id]);
    const {
      id,
      no_agenda,
      dari,
      isi_ringkas,
      no_surat,
      tgl_surat,
      sifat,
      kode,
      file,
      userName,
      nama_role,
    } = surat;
    const formattedDate = moment(tgl_surat).format("DD MMMM YYYY");
    const fullFilePath = `${SuratMasukUpload}${file}`;

    return (
      <Card border="bottom" className="hover-state rounded-0 py-3">
        <Card.Body className="d-sm-flex align-items-md-center flex-wrap flex-lg-nowrap py-0 ps-sm-0">
          <Col
            xs="auto"
            lg={1}
            className="text-start text-sm-center ps-0 ps-sm-4 mb-2 mb-sm-0"
          >
            <b>{no_agenda}</b>
            <br />
            {file && (
              <Button
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  margin: 0,
                }}
                onClick={() => handleOpenViewer(fullFilePath)}
              >
                <FontAwesomeIcon
                  icon={faFilePdf}
                  className="me-2"
                  title="Lihat Surat"
                  style={{ color: "black" }}
                />
              </Button>
            )}
          </Col>
          <Col xs={10} lg={8} className="d-block px-0 mb-4 mb-md-0">
            <div className="mb-2">
              <h5>
                {" "}
                <FontAwesomeIcon icon={faArrowDown} className="me-2" /> {dari}
              </h5>
              <div className="d-block d-sm-flex">
                <div>
                  <h6 className="fw-normal mb-3 mb-sm-0">
                    {no_surat} <b>{formattedDate}</b>
                  </h6>
                </div>
                <div className="ms-sm-3">
                  <Badge bg="danger" className="super-badge badge-lg">
                    {sifat}
                  </Badge>
                   
                  
                </div>
                 {hasDisposisi ? ( 
                    <div className="ms-sm-3">
                     <FontAwesomeIcon icon={faShare}  title="Disposisi Keluar" className="me-2" />
                    </div>
                  ) : ( null ) }
              </div>
            </div>

            <div>
              <div className="fw-bold text-dark">
                <span className={`fw-normal text-gray`}>{isi_ringkas}</span>
              </div>
            </div>
          </Col>

          <Col
            xs={10}
            sm={2}
            lg={2}
            xl={2}
            className="d-none d-lg-block d-xl-inline-flex align-items-center ms-lg-auto text-end justify-content-end px-md-0"
          >
            <Dropdown className="ms-3">
              <Dropdown.Toggle
                as={Button}
                variant="link"
                className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="icon icon-sm icon-dark">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-right">
                <Dropdown.Item
                  as={Link}
                  to={`/user/suratmasuk/view/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEye} className="me-2" /> Lihat Detail
                </Dropdown.Item>
                 <Dropdown.Item
                  as={Link}
                  to={`/user/disposisi/track/${id}`}
                  className="ms-0"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faSearch} className="me-2" /> Lacak Disposisi
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/user/disposisi/keluar/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEdit} className="me-2" /> Disposisi
                  Keluar
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/user/disposisi/cetak/${id}`}
                  className="ms-0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faPrint} className="me-2" /> Cetak
                  Disposisi
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton count={7} height={35} />
      ) : (
        <Card className="table-wrapper table-responsive shadow-sm">
          {suratmasuk.length === 0 ? (
            <div className="text-center pt-3">Tidak ada data</div>
          ) : (
            <div>
              {suratmasuk.map((surat, index) => (
                <TableRow key={surat.id} surat={surat} index={index} />
              ))}
            </div>
          )}
           {suratmasuk.length !== 0 ? (
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
            
            <Nav>
              <Pagination className="mb-2 mb-lg-0">
                <Pagination.Prev
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </Pagination.Prev>

                {paginationRange.map((page) => (
                  <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => setCurrentPage(page)}
                  >
                    {page}
                  </Pagination.Item>
                ))}

                <Pagination.Next
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </Pagination.Next>
              </Pagination>
            </Nav>
          
            <small className="fw-bold">
              Showing <b>{suratmasuk.length}</b> out of <b>{totalRecords}</b>{" "}
              entries
            </small>
          </Card.Footer>
            ) : ( null ) }

          {/* Delete modal */}
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedSuratId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/user/suratmasuk/delete`}
            />
          )}

          {isViewerOpen && (
            <DocumentViewer file={file} onClose={handleCloseViewer} />
          )}
        </Card>
      )}
    </>
  );
};

export const SuratMasukSearchTable = () => {
  moment.locale("id");
  const [suratmasuk, setSuratMasuk] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSuratId, setSelectedSuratId] = useState(null);
  const itemsPerPage = 10;
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [file, setFile] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get("query");

  const handleOpenViewer = (file) => {
    setFile(file);
    setIsViewerOpen(true);
  };

  const handleCloseViewer = () => {
    setIsViewerOpen(false);
    setFile(null);
  };

  const fetchSuratMasukSearch = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const year = localStorage.getItem("year");

    try {
      const response = await axios.get(
        `${BaseUrl}/api/suratmasuk/search?query=${searchQuery}&year=${year}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = response.data;
      const suratMasukWithUser = data.map((item) => ({
        ...item,
        userName: item.user?.name || "Unknown", // Add userName field, default to "Unknown" if not present
      }));

      setSuratMasuk(suratMasukWithUser);
      console.log(response.data.last_page);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching surat masuk:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSuratMasukSearch();
  }, [searchQuery, currentPage]);

  const handleDeleteClick = (suratId) => {
    setSelectedSuratId(suratId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteSuccess = async () => {
    setCurrentPage(1);
    await fetchSuratMasukSearch();
  };

  const maxPagesToShow = 10;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ surat, index }) => {
    const {
      id,
      no_agenda,
      dari,
      isi_ringkas,
      no_surat,
      tgl_surat,
      sifat,
      kode,
      file,
      userName,
    } = surat;
    const formattedDate = moment(tgl_surat).format("DD MMMM YYYY");
    const fullFilePath = `${SuratMasukUpload}${file}`;

    return (
      <Card border="bottom" className="hover-state rounded-0 py-3">
        <Card.Body className="d-sm-flex align-items-md-center flex-wrap flex-lg-nowrap py-0 ps-sm-0">
          <Col
            xs="auto"
            lg={1}
            className="text-start text-sm-center ps-0 ps-sm-4 mb-2 mb-sm-0"
          >
            <b>{no_agenda}</b>
            <br />
            {file && (
              <Button
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  margin: 0,
                }}
                onClick={() => handleOpenViewer(fullFilePath)}
              >
                <FontAwesomeIcon
                  icon={faFilePdf}
                  className="me-2"
                  style={{ color: "black" }}
                />
              </Button>
            )}
          </Col>
          <Col xs={10} lg={8} className="d-block px-0 mb-4 mb-md-0">
            <div className="mb-2">
              <h5>
                <FontAwesomeIcon icon={faArrowDown} className="me-2" /> {dari}
              </h5>
              <div className="d-block d-sm-flex">
                <div>
                  <h6 className="fw-normal mb-3 mb-sm-0">
                    {no_surat} <b>{formattedDate}</b>
                  </h6>
                </div>
                <div className="ms-sm-3">
                  <Badge bg="info" className="super-badge badge-lg">
                    {userName}
                  </Badge>
                </div>
                <div className="ms-sm-3">
                  <Badge bg="danger" className="super-badge badge-lg">
                    {sifat}
                  </Badge>
                </div>
              </div>
            </div>

            <div>
              <div className="fw-bold text-dark">
                <span className={`fw-normal text-gray`}>{isi_ringkas}</span>
              </div>
            </div>
          </Col>

          <Col
            xs={10}
            sm={2}
            lg={2}
            xl={2}
            className="d-none d-lg-block d-xl-inline-flex align-items-center ms-lg-auto text-end justify-content-end px-md-0"
          >
            <Dropdown className="ms-3">
              <Dropdown.Toggle
                as={Button}
                variant="link"
                className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="icon icon-sm icon-dark">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-right">
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratmasuk/view/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEye} className="me-2" /> Lihat Detail
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratmasuk/edit/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-danger ms-0"
                  onClick={() => handleDeleteClick(id)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Delete
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratmasuk/kendali/${id}`}
                  className="ms-0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faPrint} className="me-2" /> Cetak
                  Surat Kendali
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratmasuk/terima/${id}`}
                  className="ms-0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faPrint} className="me-2" /> Cetak
                  Surat Tanda Terima
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton count={7} height={35} />
      ) : (
        <Card className="table-wrapper table-responsive shadow-sm">
          {suratmasuk.length === 0 ? (
            <div className="text-center pt-3">Tidak ada data</div>
          ) : (
            <div>
              {suratmasuk.map((surat, index) => (
                <TableRow key={index} surat={surat} index={index} />
              ))}
            </div>
          )}
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
            <Nav>
              <Pagination className="mb-2 mb-lg-0">
                <Pagination.Prev
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </Pagination.Prev>

                {paginationRange.map((page) => (
                  <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => setCurrentPage(page)}
                  >
                    {page}
                  </Pagination.Item>
                ))}

                <Pagination.Next
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </Pagination.Next>
              </Pagination>
            </Nav>
            <small className="fw-bold">
              Showing <b>{suratmasuk.length}</b> out of <b>{totalRecords}</b>{" "}
              entries
            </small>
          </Card.Footer>

          {/* Delete modal */}
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedSuratId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/suratmasuk/delete`}
            />
          )}

          {isViewerOpen && (
            <DocumentViewer file={file} onClose={handleCloseViewer} />
          )}
        </Card>
      )}
    </>
  );
};

export const SuratKeluarTable = () => {
  moment.locale("id");
  const [suratkeluar, setSuratKeluar] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSuratId, setSelectedSuratId] = useState(null);
  const itemsPerPage = 10;
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [file, setFile] = useState(null);

  const handleOpenViewer = (file) => {
    setFile(file);
    setIsViewerOpen(true);
  };

  const handleCloseViewer = () => {
    setIsViewerOpen(false);
    setFile(null);
  };

  const fetchSuratKeluar = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const year = localStorage.getItem("year");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/suratkeluar/list?year=${year}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = response.data;
      // Map through data to include user.name
      const suratKeluarWithUser = data.map((item) => ({
        ...item,
        userName: item.user?.name || "Unknown", // Add userName field, default to "Unknown" if not present
      }));

      setSuratKeluar(suratKeluarWithUser);
      console.log(suratKeluarWithUser);
      //console.log(data);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching surat keluar:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSuratKeluar();
  }, [currentPage]);

  const handleDeleteClick = (suratId) => {
    setSelectedSuratId(suratId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteSuccess = async () => {
    setCurrentPage(1);
    await fetchSuratKeluar();
  };

  const maxPagesToShow = 10;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ surat, index }) => {
    const {
      id,
      no_agenda,
      dari,
      isi_ringkas,
      tujuan,
      no_surat,
      tgl_surat,
      sifat,
      kode,
      file,
      userName,
    } = surat;
    const formattedDate = moment(tgl_surat).format("DD MMMM YYYY");
    const fullFilePath = `${SuratKeluarUpload}${file}`;

    return (
      <Card border="bottom" className="hover-state rounded-0 py-3">
        <Card.Body className="d-sm-flex align-items-md-center flex-wrap flex-lg-nowrap py-0 ps-sm-0">
          <Col
            xs="auto"
            lg={1}
            className="text-start text-sm-center ps-0 ps-sm-4 mb-2 mb-sm-0"
          >
            <b>{no_agenda}</b>
            <br />
            {file && (
              <Button
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  margin: 0,
                }}
                onClick={() => handleOpenViewer(fullFilePath)}
              >
                <FontAwesomeIcon
                  icon={faFilePdf}
                  className="me-2"
                  style={{ color: "black" }}
                />
              </Button>
            )}
          </Col>
          <Col xs={10} lg={8} className="d-block px-0 mb-4 mb-md-0">
            <div className="mb-2">
              <h5>
                {" "}
                <FontAwesomeIcon icon={faArrowUp} className="me-2" /> {tujuan}
              </h5>
              <div className="d-block d-sm-flex">
                <div>
                  <h6 className="fw-normal mb-3 mb-sm-0">
                    {no_surat} <b>{formattedDate}</b>
                  </h6>
                </div>
                <div className="ms-sm-3">
                  <Badge bg="danger" className="super-badge badge-lg">
                    {sifat}
                  </Badge>
                </div>
              </div>
            </div>

            <div>
              <div className="fw-bold text-dark">
                <span className={`fw-normal text-gray`}>{isi_ringkas}</span>
              </div>
            </div>
          </Col>

          <Col
            xs={10}
            sm={2}
            lg={2}
            xl={2}
            className="d-none d-lg-block d-xl-inline-flex align-items-center ms-lg-auto text-end justify-content-end px-md-0"
          >
            <Dropdown className="ms-3">
              <Dropdown.Toggle
                as={Button}
                variant="link"
                className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="icon icon-sm icon-dark">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-right">
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratkeluar/view/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEye} className="me-2" /> Lihat Detail
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratkeluar/edit/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-danger ms-0"
                  onClick={() => handleDeleteClick(id)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Delete
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratkeluar/kendali/${id}`}
                  className="ms-0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faPrint} className="me-2" /> Cetak
                  Surat Kendali
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratkeluar/terima/${id}`}
                  className="ms-0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faPrint} className="me-2" /> Cetak
                  Surat Tanda Terima
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton count={7} height={35} />
      ) : (
        <Card className="table-wrapper table-responsive shadow-sm">
          {suratkeluar.length === 0 ? (
            <div className="text-center pt-3">Tidak ada data</div>
          ) : (
            <div>
              {suratkeluar.map((surat, index) => (
                <TableRow key={index} surat={surat} index={index} />
              ))}
            </div>
          )}
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
            <Nav>
              <Pagination className="mb-2 mb-lg-0">
                <Pagination.Prev
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </Pagination.Prev>

                {paginationRange.map((page) => (
                  <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => setCurrentPage(page)}
                  >
                    {page}
                  </Pagination.Item>
                ))}

                <Pagination.Next
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </Pagination.Next>
              </Pagination>
            </Nav>
            <small className="fw-bold">
              Showing <b>{suratkeluar.length}</b> out of <b>{totalRecords}</b>{" "}
              entries
            </small>
          </Card.Footer>

          {/* Delete modal */}
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedSuratId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/suratkeluar/delete`}
            />
          )}

          {isViewerOpen && (
            <DocumentViewer file={file} onClose={handleCloseViewer} />
          )}
        </Card>
      )}
    </>
  );
};

export const SuratKeluarSearchTable = () => {
  moment.locale("id");
  const [suratkeluar, setSuratKeluar] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSuratId, setSelectedSuratId] = useState(null);
  const itemsPerPage = 10;
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [file, setFile] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get("query");

  const handleOpenViewer = (file) => {
    setFile(file);
    setIsViewerOpen(true);
  };

  const handleCloseViewer = () => {
    setIsViewerOpen(false);
    setFile(null);
  };

  const fetchSuratKeluar = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const year = localStorage.getItem("year");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/suratkeluar/search?query=${searchQuery}&year=${year}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = response.data;
      // Map through data to include user.name
      const suratKeluarWithUser = data.map((item) => ({
        ...item,
        userName: item.user?.name || "Unknown", // Add userName field, default to "Unknown" if not present
      }));

      setSuratKeluar(suratKeluarWithUser);
      console.log(suratKeluarWithUser);
      //console.log(data);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching surat keluar:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSuratKeluar();
  }, [currentPage]);

  const handleDeleteClick = (suratId) => {
    setSelectedSuratId(suratId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteSuccess = async () => {
    setCurrentPage(1);
    await fetchSuratKeluar();
  };

  const maxPagesToShow = 10;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ surat, index }) => {
    const {
      id,
      no_agenda,
      dari,
      isi_ringkas,
      tujuan,
      no_surat,
      tgl_surat,
      sifat,
      kode,
      file,
      userName,
    } = surat;
    const formattedDate = moment(tgl_surat).format("DD MMMM YYYY");
    const fullFilePath = `${SuratKeluarUpload}${file}`;

    return (
      <Card border="bottom" className="hover-state rounded-0 py-3">
        <Card.Body className="d-sm-flex align-items-md-center flex-wrap flex-lg-nowrap py-0 ps-sm-0">
          <Col
            xs="auto"
            lg={1}
            className="text-start text-sm-center ps-0 ps-sm-4 mb-2 mb-sm-0"
          >
            <b>{no_agenda}</b>
            <br />
            {file && (
              <Button
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  margin: 0,
                }}
                onClick={() => handleOpenViewer(fullFilePath)}
              >
                <FontAwesomeIcon
                  icon={faFilePdf}
                  className="me-2"
                  style={{ color: "black" }}
                />
              </Button>
            )}
          </Col>
          <Col xs={10} lg={8} className="d-block px-0 mb-4 mb-md-0">
            <div className="mb-2">
              <h5>
                {" "}
                <FontAwesomeIcon icon={faArrowUp} className="me-2" /> {tujuan}
              </h5>
              <div className="d-block d-sm-flex">
                <div>
                  <h6 className="fw-normal mb-3 mb-sm-0">
                    {no_surat} <b>{formattedDate}</b>
                  </h6>
                </div>
                <div className="ms-sm-3">
                  <Badge bg="danger" className="super-badge badge-lg">
                    {sifat}
                  </Badge>
                </div>
              </div>
            </div>

            <div>
              <div className="fw-bold text-dark">
                <span className={`fw-normal text-gray`}>{isi_ringkas}</span>
              </div>
            </div>
          </Col>

          <Col
            xs={10}
            sm={2}
            lg={2}
            xl={2}
            className="d-none d-lg-block d-xl-inline-flex align-items-center ms-lg-auto text-end justify-content-end px-md-0"
          >
            <Dropdown className="ms-3">
              <Dropdown.Toggle
                as={Button}
                variant="link"
                className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="icon icon-sm icon-dark">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-right">
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratkeluar/view/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEye} className="me-2" /> Lihat Detail
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratkeluar/edit/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-danger ms-0"
                  onClick={() => handleDeleteClick(id)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Delete
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratkeluar/kendali/${id}`}
                  className="ms-0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faPrint} className="me-2" /> Cetak
                  Surat Kendali
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratkeluar/terima/${id}`}
                  className="ms-0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faPrint} className="me-2" /> Cetak
                  Surat Tanda Terima
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton count={7} height={35} />
      ) : (
        <Card className="table-wrapper table-responsive shadow-sm">
          {suratkeluar.length === 0 ? (
            <div className="text-center pt-3">Tidak ada data</div>
          ) : (
            <div>
              {suratkeluar.map((surat, index) => (
                <TableRow key={index} surat={surat} index={index} />
              ))}
            </div>
          )}
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
            <Nav>
              <Pagination className="mb-2 mb-lg-0">
                <Pagination.Prev
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </Pagination.Prev>

                {paginationRange.map((page) => (
                  <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => setCurrentPage(page)}
                  >
                    {page}
                  </Pagination.Item>
                ))}

                <Pagination.Next
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </Pagination.Next>
              </Pagination>
            </Nav>
            <small className="fw-bold">
              Showing <b>{suratkeluar.length}</b> out of <b>{totalRecords}</b>{" "}
              entries
            </small>
          </Card.Footer>

          {/* Delete modal */}
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedSuratId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/suratkeluar/delete`}
            />
          )}

          {isViewerOpen && (
            <DocumentViewer file={file} onClose={handleCloseViewer} />
          )}
        </Card>
      )}
    </>
  );
};

export const KlasifikasiTable = () => {
  const [klasifikasi, setKlasifikasi] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedKlasifikasiId, setSelectedKlasifikasiId] = useState(null);
  const itemsPerPage = 10;

  const fetchKlasifikasi = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/klasifikasi/list?page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data, totalPages } = response.data;
      setKlasifikasi(data);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching klasifikasi:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchKlasifikasi();
  }, [currentPage]);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const maxPagesToShow = 10;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ klasifikasi, index }) => {
    const { id, kode, nama } = klasifikasi;

    return (
      <tr>
        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
        <td>{kode}</td>
        <td>{nama}</td>
      </tr>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton count={7} />
      ) : (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">No.</th>
                  <th className="border-bottom">Kode</th>
                  <th className="border-bottom">Nama</th>
                </tr>
              </thead>
              {klasifikasi.length === 0 ? (
                <div className="text-center pt-3">Tidak ada data</div>
              ) : (
                <tbody>
                  {klasifikasi.map((klasifikasi, index) => (
                    <TableRow
                      key={index}
                      klasifikasi={klasifikasi}
                      index={index}
                    />
                  ))}
                </tbody>
              )}
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
              <Nav>
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Pagination.Prev>

                  {paginationRange.map((page) => (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => setCurrentPage(page)}
                    >
                      {page}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </Nav>
              <small className="fw-bold">
                Showing <b>{klasifikasi.length}</b> out of <b>{totalRecords}</b>{" "}
                entries
              </small>
            </Card.Footer>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export const KlasifikasiSearchTable = () => {
  const [klasifikasi, setKlasifikasi] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedKlasifikasiId, setSelectedKlasifikasiId] = useState(null);
  const itemsPerPage = 10;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get("query");

  const fetchKlasifikasi = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/klasifikasi/searchdetail?query=${searchQuery}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = response.data;
      console.log(response.data);
      setKlasifikasi(data);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching klasifikasi:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchKlasifikasi();
  }, [currentPage, searchQuery]);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const maxPagesToShow = 10;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ klasifikasi, index }) => {
    const { id, kode, nama } = klasifikasi;

    return (
      <tr>
        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
        <td>{kode}</td>
        <td>{nama}</td>
      </tr>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton count={7} />
      ) : (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">No.</th>
                  <th className="border-bottom">Kode</th>
                  <th className="border-bottom">Nama</th>
                </tr>
              </thead>
              {klasifikasi.length === 0 ? (
                <div className="text-center pt-3">Tidak ada data</div>
              ) : (
                <tbody>
                  {klasifikasi.map((klasifikasi, index) => (
                    <TableRow
                      key={index}
                      klasifikasi={klasifikasi}
                      index={index}
                    />
                  ))}
                </tbody>
              )}
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
              <Nav>
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Pagination.Prev>

                  {paginationRange.map((page) => (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => setCurrentPage(page)}
                    >
                      {page}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </Nav>
              <small className="fw-bold">
                Showing <b>{klasifikasi.length}</b> out of <b>{totalRecords}</b>{" "}
                entries
              </small>
            </Card.Footer>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export const RoleTable = () => {
  const [role, setRole] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const itemsPerPage = 10;

  const fetchRole = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/role/list?page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data, totalPages } = response.data;
      setRole(data);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching role:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRole();
  }, [currentPage]);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (suratId) => {
    setSelectedRoleId(suratId);
    setShowDeleteModal(true);
  };

  const handleDeleteSuccess = async () => {
    setCurrentPage(1);
    await fetchRole();
  };

  const maxPagesToShow = 10;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ role, index }) => {
    const { id, nama } = role;

    return (
      <tr>
        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
        <td>{role.nama}</td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to={`/admin/users/role/edit/${id}`}
                className="ms-0"
              >
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item
                className="text-danger ms-0"
                onClick={() => handleDeleteClick(role.id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton count={7} />
      ) : (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">No.</th>
                  <th className="border-bottom">Nama</th>
                  <th className="border-bottom">Aksi</th>
                </tr>
              </thead>
              {role.length === 0 ? (
                <div className="text-center pt-3">Tidak ada data</div>
              ) : (
                <tbody>
                  {role.map((role, index) => (
                    <TableRow key={index} role={role} index={index} />
                  ))}
                </tbody>
              )}
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
              <Nav>
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Pagination.Prev>

                  {paginationRange.map((page) => (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => setCurrentPage(page)}
                    >
                      {page}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </Nav>
              <small className="fw-bold">
                Showing <b>{role.length}</b> out of <b>{totalRecords}</b>{" "}
                entries
              </small>
            </Card.Footer>
          </Card.Body>

          {/* Delete modal */}
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedRoleId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/role/delete`}
            />
          )}
        </Card>
      )}
    </>
  );
};

export const AccountTable = () => {
  const [account, setAccount] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const itemsPerPage = 10;

  const fetchAccount = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/users/listpaginate?page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data, totalPages } = response.data;
      console.log(data);
      setAccount(data);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching role:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccount();
  }, [currentPage]);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (suratId) => {
    setSelectedAccountId(suratId);
    setShowDeleteModal(true);
  };

  const handleDeleteSuccess = async () => {
    setCurrentPage(1);
    await fetchAccount();
  };

  const maxPagesToShow = 10;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ account, index }) => {
    return (
        <tr>
            <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
            <td>{account.name} </td>
            <td>{account.email}</td>
            <td>{account.hak_akses}</td>
            <td>
                {account.periode.length > 0 
                    ? account.periode.map((periode) => (
                        <div key={periode.id}>{periode.role.nama}</div>
                    ))
                    : "-"}
            </td>
            <td>{account.status}</td>
            <td>
                <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle
                        as={Button}
                        split
                        variant="link"
                        className="text-dark m-0 p-0"
                    >
                        <span className="icon icon-sm">
                            <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            as={Link}
                            to={`/admin/users/account/edit/${account.id}`}
                            className="ms-0"
                        >
                            <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                            className="text-danger ms-0"
                            onClick={() => handleDeleteClick(account.id)}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Delete
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </td>
        </tr>
    );
};


  return (
    <>
      {loading ? (
        <Skeleton count={7} />
      ) : (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">No.</th>
                  <th className="border-bottom">Nama</th>
                  <th className="border-bottom">Email</th>
                  <th className="border-bottom">Hak Akses</th>
                  <th className="border-bottom">Jabatan Aktif (dari periode)</th>
                  <th className="border-bottom">Status</th>
                  <th className="border-bottom">Aksi</th>
                </tr>
              </thead>
              {account.length === 0 ? (
                <div className="text-center pt-3">Tidak ada data</div>
              ) : (
                <tbody>
                  {account.map((account, index) => (
                    <TableRow
                      key={account.id}
                      account={account}
                      index={index}
                    />
                  ))}
                </tbody>
              )}
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
              <Nav>
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Pagination.Prev>

                  {paginationRange.map((page) => (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => setCurrentPage(page)}
                    >
                      {page}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </Nav>
              <small className="fw-bold">
                Showing <b>{account.length}</b> out of <b>{totalRecords}</b>{" "}
                entries
              </small>
            </Card.Footer>
          </Card.Body>

          {/* Delete modal */}
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedAccountId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/users/delete`}
            />
          )}
        </Card>
      )}
    </>
  );
};

export const PeriodeTable = () => {
  const [periode, setPeriode] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const itemsPerPage = 10;

  const fetchPeriode = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/periode/list?page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data, totalPages } = response.data;
      console.log(data);
      setPeriode(data);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching role:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPeriode();
  }, [currentPage]);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (suratId) => {
    setSelectedAccountId(suratId);
    setShowDeleteModal(true);
  };

  const handleDeleteSuccess = async () => {
    setCurrentPage(1);
    await fetchPeriode();
  };

  const maxPagesToShow = 10;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ periode, index }) => {
    return (
      <tr>
        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
        <td>{periode.user.name}</td>
        <td>{periode.role.nama}</td>
        <td>
          {periode.start_date
            ? moment(periode.start_date).format("DD MMMM YYYY")
            : "-"}
        </td>
        <td>
          {periode.end_date
            ? moment(periode.end_date).format("DD MMMM YYYY")
            : "-"}
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to={`/admin/users/periode/edit/${periode.id}`}
                className="ms-0"
              >
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item
                className="text-danger ms-0"
                onClick={() => handleDeleteClick(periode.id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton count={7} />
      ) : (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">No.</th>
                  <th className="border-bottom">Nama</th>
                  <th className="border-bottom">Jabatan</th>
                  <th className="border-bottom">Tanggal Mulai</th>
                  <th className="border-bottom">Tanggal Akhir</th>
                  <th className="border-bottom">Aksi</th>
                </tr>
              </thead>
              {periode.length === 0 ? (
                <div className="text-center pt-3">Tidak ada data</div>
              ) : (
                <tbody>
                  {periode.map((periode, index) => (
                    <TableRow
                      key={periode.id}
                      periode={periode}
                      index={index}
                    />
                  ))}
                </tbody>
              )}
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
              <Nav>
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Pagination.Prev>

                  {paginationRange.map((page) => (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => setCurrentPage(page)}
                    >
                      {page}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </Nav>
              <small className="fw-bold">
                Showing <b>{periode.length}</b> out of <b>{totalRecords}</b>{" "}
                entries
              </small>
            </Card.Footer>
          </Card.Body>

          {/* Delete modal */}
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedAccountId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/periode/delete`}
            />
          )}
        </Card>
      )}
    </>
  );
};

export const DisposisiTable = () => {
  const { id } = useParams();
  const [disposisi, setDisposisi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDisposisiId, setSelectedDisposisiId] = useState(null);
  const itemsPerPage = 10;

  const fetchDisposisi = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/disposisi/show/${id}/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      //const { data, totalPages } = response.data;
      setDisposisi(response.data.data);
    } catch (error) {
      console.error("Error fetching disposisi:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDisposisi();
  }, []);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (suratId) => {
    setSelectedDisposisiId(suratId);
    setShowDeleteModal(true);
  };

  const handleDeleteSuccess = async () => {
    await fetchDisposisi();
  };

  const TableRow = ({ disposisi, index }) => {
    //const { id, kode, nama } = disposisi;

    return (
      <tr>
        <td>{index + 1}</td> {/* Menampilkan nomor urut */}
        <td>
         {disposisi.nama_penerima}
         {disposisi.nama_penerima && disposisi.role ? ` [${disposisi.role}]` : ""}
        </td>
        <td>{disposisi.isi}</td>
        <td>{disposisi.sifat}</td>
        <td>{disposisi.catatan}</td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to={`/user/disposisi/edit/${disposisi.id}/${disposisi.surat_id}`}
                className="ms-0"
              >
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item
                className="text-danger ms-0"
                onClick={() => handleDeleteClick(disposisi.id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton count={7} />
      ) : (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">No</th>
                  <th className="border-bottom">Penerima</th>
                  <th className="border-bottom">Isi</th>
                  <th className="border-bottom">Sifat</th>
                  <th className="border-bottom">Catatan</th>
                  <th className="border-bottom">Aksi</th>
                </tr>
              </thead>

              <tbody>
                {disposisi.map((disposisi, index) => (
                  <TableRow key={index} disposisi={disposisi} index={index} />
                ))}
              </tbody>
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5"></Card.Footer>
          </Card.Body>
             {showDeleteModal && (
            <CommonDeleteModal
              id={selectedDisposisiId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/disposisi/delete`}
            />
          )}
        </Card>
        
       
      )}
    </>
  );
};

export const DisposisiAccessTable = () => {
  const [access, setAccess] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const itemsPerPage = 10;

  const fetchAccess = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/disposisi/acclist?page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data, totalPages } = response.data;
      console.log(data);
      setAccess(data);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching role:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccess();
  }, [currentPage]);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (suratId) => {
    setSelectedAccountId(suratId);
    setShowDeleteModal(true);
  };

  const handleDeleteSuccess = async () => {
    setCurrentPage(1);
    await fetchAccess();
  };

  const maxPagesToShow = 10;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ access, index }) => {
    return (
      <tr>
        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
        <td>
  {access.pemberi.name}
  {access.pemberi.periode_jabatan && access.pemberi.periode_jabatan.role 
    ? `[${access.pemberi.periode_jabatan.role.nama}]` 
    : "Role tidak ditemukan"}
</td>

<td>
  {access.penerima.name}
  {access.penerima.periode_jabatan && access.penerima.periode_jabatan.role 
    ? `[${access.penerima.periode_jabatan.role.nama}]` 
    : "Role tidak ditemukan"}
</td> 
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to={`/admin/users/disposisi/edit/${access.id}`}
                className="ms-0"
              >
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item
                className="text-danger ms-0"
                onClick={() => handleDeleteClick(access.id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton count={7} />
      ) : (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">No.</th>
                  <th className="border-bottom">Pembuat Disposisi</th>
                  <th className="border-bottom">Penerima Disposisi</th>
                  <th className="border-bottom">Aksi</th>
                </tr>
              </thead>
              {access.length === 0 ? (
                <div className="text-center pt-3">Tidak ada data</div>
              ) : (
                <tbody>
                  {access.map((access, index) => (
                    <TableRow key={access.id} access={access} index={index} />
                  ))}
                </tbody>
              )}
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
              <Nav>
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Pagination.Prev>

                  {paginationRange.map((page) => (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => setCurrentPage(page)}
                    >
                      {page}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </Nav>
              <small className="fw-bold">
                Showing <b>{access.length}</b> out of <b>{totalRecords}</b>{" "}
                entries
              </small>
            </Card.Footer>
          </Card.Body>

          {/* Delete modal */}
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedAccountId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/disposisi/accdelete`}
            />
          )}
        </Card>
      )}
    </>
  );
};

export const DisposisiInTable = () => {
  const { id } = useParams();
  const [disposisi, setDisposisi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDisposisiId, setSelectedDisposisiId] = useState(null);
  const itemsPerPage = 10;

  const fetchDisposisi = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/disposisi/showin/${id}/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      //const { data, totalPages } = response.data;
      setDisposisi(response.data.data);
    } catch (error) {
      console.error("Error fetching disposisi:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDisposisi();
  }, []);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (suratId) => {
    setSelectedDisposisiId(suratId);
    setShowDeleteModal(true);
  };

  const handleDeleteSuccess = async () => {
    await fetchDisposisi();
  };

  const TableRow = ({ disposisi, index }) => {
    //const { id, kode, nama } = disposisi;

    return (
      <tr>
        <td>{index + 1}</td> {/* Menampilkan nomor urut */}
        <td>
         {disposisi.nama_pengirim && disposisi.role
         ? `${disposisi.nama_pengirim} ${disposisi.role ? ` [${disposisi.role}]` : ""}` 
        : "N/A"}
          </td>
        <td>{moment(disposisi.created_at).format('DD MMMM YYYY, HH:mm ')}</td>
        <td>{disposisi.terbaca === 'Y' ? 'Sudah Dibaca' : 'Belum Dibaca'}</td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to={`/user/disposisi/view/${disposisi.id}/${disposisi.surat_id}`}
                className="ms-0"
              >
                <FontAwesomeIcon icon={faEye} className="me-2" /> Baca Disposisi
              </Dropdown.Item>
             
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton count={7} />
      ) : (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">No</th>
                  <th className="border-bottom">Asal Disposisi</th>
                  <th className="border-bottom">Dikirim Pada</th>
                  <th className="border-bottom">Status</th>
                  <th className="border-bottom">Aksi</th>
                </tr>
              </thead>

              <tbody>
                {disposisi.map((disposisi, index) => (
                  <TableRow key={index} disposisi={disposisi} index={index} />
                ))}
              </tbody>
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5"></Card.Footer>
          </Card.Body>
             {showDeleteModal && (
            <CommonDeleteModal
              id={selectedDisposisiId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/disposisi/delete`}
            />
          )}
        </Card>
        
       
      )}
    </>
  );
};

export const DisposisiTrackTable = () => {
  const { id } = useParams();
  const [disposisi, setDisposisi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDisposisiId, setSelectedDisposisiId] = useState(null);
  const itemsPerPage = 10;

  const fetchDisposisi = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/disposisi/tracking/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      //const { data, totalPages } = response.data;
      setDisposisi(response.data.data);
    } catch (error) {
      console.error("Error fetching disposisi:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDisposisi();
  }, []);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (suratId) => {
    setSelectedDisposisiId(suratId);
    setShowDeleteModal(true);
  };

  const handleDeleteSuccess = async () => {
    await fetchDisposisi();
  };

  const TableRow = ({ disposisi, index }) => {
    //const { id, kode, nama } = disposisi;

    return (
      <tr>
  <td>{index + 1}</td> {/* Menampilkan nomor urut */}
   <td>{moment(disposisi.created_at).format('DD MMMM YYYY, HH:mm')}</td>
  <td>
  {disposisi.pejabat_nama ? (
    <>
      {disposisi.pejabat_nama}
      <br />
      {disposisi.pejabat_role ? ` [${disposisi.pejabat_role}]` : ""}
    </>
  ) : (
    "N/A"
  )}
</td>
<td>
  {disposisi.penerima_nama ? (
    <>
      {disposisi.penerima_nama}
      <br />
      {disposisi.penerima_role ? ` [${disposisi.penerima_role}]` : ""}
    </>
  ) : (
    "N/A"
  )}
</td>
  
  <td>{disposisi.isi}</td>
  <td>{disposisi.sifat}</td>
  <td>{disposisi.terbaca === 'Y' ? 'Sudah Dibaca' : 'Belum Dibaca'}</td>
  
</tr>

    );
  };

  return (
    <>
      {loading ? (
        <Skeleton count={7} />
      ) : (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">No</th>
                  <th className="border-bottom">Dikirim Pada</th>
                  <th className="border-bottom">Asal Disposisi</th>
                  <th className="border-bottom">Diteruskan Pada</th>
                  <th className="border-bottom">Isi Disposisi</th>
                  <th className="border-bottom">Sifat</th>
                  <th className="border-bottom">Status</th>
                </tr>
              </thead>

              <tbody>
                {disposisi.map((disposisi, index) => (
                  <TableRow key={index} disposisi={disposisi} index={index} />
                ))}
              </tbody>
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5"></Card.Footer>
          </Card.Body>
            
        </Card>
        
       
      )}
    </>
  );
};


