import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
  Spinner,
} from "@themesberg/react-bootstrap";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { BaseUrl } from "../routes/BaseUrl";
import { toast } from "react-toastify";
import Autosuggest from "react-autosuggest";

export const GeneralInfoForm = () => {
  const token = localStorage.getItem("token");
  const { id } = useParams();
  const Navigate = useNavigate();
  const [formData, setFormData] = useState({
    kodeKlasifikasi: "",
    asalSurat: "",
    indeksBerkas: "",
    nomorSurat: "",
    tanggalSurat: "",
    perihal: "",
    tanggalDiterima: "",
    isiRingkas: "",
    fileSurat: null,
    sifatSurat: "",
    keterangan: "",
    tujuanSurat: "",
    namaPenerima: "",
    tanggalCetak: "",
    noTelpPenerima: "",
  });
  const [errors, setErrors] = useState({});

  const [options, setOptions] = useState([]);
  const [noAgenda, setNoAgenda] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const fetchSuratMasuk = async () => {
      try {
        const response = await axios.get(
          BaseUrl + `/api/suratmasuk/show/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Replace with your actual token
            },
          }
        );
        setFormData(response.data); // Assuming response.data is an array of options
        setValue(response.data.kodeKlasifikasi);
        updateInputValue(response.data.asalSurat);
        console.log(response.data);
        console.log(response.data.tujuanSurat);
      } catch (error) {
        console.error("Error fetching surat masuk:", error);
        setErrors({ suratmasuk: "Failed to get data" });
      }
    };

    fetchSuratMasuk();
  }, []);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await axios.get(BaseUrl + `/api/periode/active`, {
          headers: {
            Authorization: `Bearer ${token}`, // Replace with your actual token
          },
        });
        setOptions(response.data); // Assuming response.data is an array of options
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching options:", error);
        setErrors({ tujuanSurat: "Failed to load options" });
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    const fetchNoAgenda = async () => {
      try {
        const response = await axios.get(BaseUrl + `/api/suratmasuk/noagenda`, {
          headers: {
            Authorization: `Bearer ${token}`, // Replace with your actual token
          },
        });
        setNoAgenda(response.data); // Assuming response.data is an array of options
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching no agenda:", error);
        setErrors({ noAgenda: "Failed to load no agenda" });
      }
    };

    fetchNoAgenda();
  },[]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      console.log(formData);
      const response = await axios.post(
        BaseUrl + `/api/suratmasuk/update/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add your token here
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        Navigate(`/admin/suratmasuk/edit/${id}`);
      } else if (response.data.status == "failed") {
        toast.error(response.data.message);
        Navigate(`/admin/suratmasuk/edit/${id}`);
        console.log("API is not working");
      } else {
        toast.error(response.data);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const [klasifikasi, setKlasifikasi] = useState([]);
  const [value, setValue] = useState(formData.kodeKlasifikasi || "");

  const fetchKlasifikasi = async (value) => {
    try {
      const response = await axios.get(
        BaseUrl + `/api/klasifikasi/search?q=${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setKlasifikasi(response.data);
    } catch (error) {
      console.error("Error fetching klasifikasi:", error);
    }
  };

  const onKlasifikasiFetchRequested = ({ value }) => {
    fetchKlasifikasi(value);
  };

  const onKlasifikasiClearRequested = () => {
    setKlasifikasi([]);
  };

  const getKlasifikasiValue = (klasifikasiItem) => klasifikasiItem.kode;

  const renderKlasifikasi = (klasifikasiItem) => (
    <div>
      <b>{klasifikasiItem.kode}</b> {klasifikasiItem.nama}
    </div>
  );

  const onSuggestionSelected = (event, { suggestion }) => {
    // Set the selected suggestion into the indeksBerkas input field
    setFormData({ ...formData, indeksBerkas: suggestion.nama });
  };

  const [asalSurat, setAsalSurat] = useState([]);
  const [inputValue, updateInputValue] = useState(formData.asalSurat || "");

  const fetchAsalSurat = async (query) => {
    try {
      const response = await axios.get(
        `${BaseUrl}/api/suratmasuk/pengirim?q=${query}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Return the data directly to use in Asal Surat
    } catch (error) {
      console.error("Error fetching Asal Surat:", error);
      return [];
    }
  };

  const onAsalSuratFetchRequested = async ({ value }) => {
    if (value) {
      const fetchedAsalSurat = await fetchAsalSurat(value);
      setAsalSurat(fetchedAsalSurat);
    } else {
      setAsalSurat([]);
    }
  };

  const onAsalSuratClearRequested = () => {
    setAsalSurat([]);
  };

  const getAsalSuratValue = (asalSuratItem) => asalSuratItem.dari; // Use `nama` as the displayed value

  const renderAsalSurat = (asalSuratItem) => <div>{asalSuratItem.dari}</div>;

  const validateInput = (value) => {
    if (!value.trim()) {
      setErrors({ ...errors, asalSurat: "Asal Surat is required." });
    } else {
      setErrors({ ...errors, asalSurat: "" });
    }
  };

  const handleInputChange = (event, { newValue }) => {
    updateInputValue(newValue);
    setFormData({ ...formData, asalSurat: newValue }); // Validate on every change
  };

  const handleInputChange2 = (event, { newValue }) => {
    setValue(newValue);
    setFormData({ ...formData, kodeKlasifikasi: newValue }); // Validate on every change
  };

  const handleInputBlur = () => {
    validateInput(inputValue); // Validate on blur
  };

  const handleInputBlur2 = () => {
    validateInput(inputValue); // Validate on blur
  };

  const CreateSuratMasuk = () => {
    return (
      <>
        <h5 className="mb-4">Edit Surat Masuk</h5>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="noAgenda">
              <Form.Label>Nomor Agenda</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Masukan Nomor Agenda"
                value={formData.noAgenda}
                isInvalid={!!errors.noAgenda}
                disabled
              />
              <Form.Control.Feedback type="invalid">
                {errors.noAgenda}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="kodeKlasifikasi">
              <Form.Label>Kode Klasifikasi</Form.Label>
              <Autosuggest
                suggestions={klasifikasi}
                onSuggestionsFetchRequested={onKlasifikasiFetchRequested}
                onSuggestionsClearRequested={onKlasifikasiClearRequested}
                getSuggestionValue={getKlasifikasiValue}
                renderSuggestion={renderKlasifikasi}
                inputProps={{
                  placeholder: "Masukan Kode Klasifikasi",
                  value: formData.kodeKlasifikasi, // Directly use formData
                  onChange: handleInputChange2,
                  className: "form-control",
                  isInvalid: !!errors.kodeKlasifikasi,
                }}
                onSuggestionSelected={(event, { suggestion }) => {
                  setFormData({
                    ...formData,
                    kodeKlasifikasi: suggestion.kode,
                    indeksBerkas: suggestion.nama,
                  });
                  setErrors({ ...errors, kodeKlasifikasi: "" });
                }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.kodeKlasifikasi}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="asalSurat">
              <Form.Label>Asal Surat</Form.Label>
              <Autosuggest
                suggestions={asalSurat}
                onSuggestionsFetchRequested={onAsalSuratFetchRequested}
                onSuggestionsClearRequested={onAsalSuratClearRequested}
                getSuggestionValue={getAsalSuratValue}
                renderSuggestion={renderAsalSurat}
                inputProps={{
                  placeholder: "Masukan Asal Surat",
                  value: inputValue,
                  onChange: handleInputChange,
                  className: "form-control", // Correctly placed `className`
                  onBlur: handleInputBlur,
                }}
                onSuggestionSelected={(event, { suggestion }) => {
                  setFormData({ ...formData, asalSurat: suggestion.dari });
                  updateInputValue(suggestion.dari);
                  setErrors({ ...errors, asalSurat: "" });
                }}
              />
              {errors.asalSurat && (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.asalSurat}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>{" "}
          <Col md={6} className="mb-3">
            <Form.Group id="indeksBerkas">
              <Form.Label>Indeks Berkas</Form.Label>
              <Form.Control
                required
                as="textarea" 
                rows="3"
                placeholder="Masukan Indeks Berkas"
                value={formData.indeksBerkas}
                onChange={(e) =>
                  setFormData({ ...formData, indeksBerkas: e.target.value })
                }
                isInvalid={!!errors.indeksBerkas}
              />
              <Form.Control.Feedback type="invalid">
                {errors.indeksBerkas}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="nomorSurat">
              <Form.Label>Nomor Surat</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Masukan Nomor Surat"
                value={formData.nomorSurat}
                onChange={(e) =>
                  setFormData({ ...formData, nomorSurat: e.target.value })
                }
                isInvalid={!!errors.nomorSurat}
              />
              <Form.Control.Feedback type="invalid">
                {errors.nomorSurat}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>{" "}
          <Col md={6} className="mb-3">
            <Form.Group id="tanggalSurat">
              <Form.Label>Tanggal Surat</Form.Label>
              <Form.Control
                type="date"
                placeholder="Tanggal Surat"
                value={formData.tanggalSurat}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    tanggalSurat: e.target.value,
                  })
                }
                isInvalid={!!errors.tanggalSurat}
              />
              <Form.Control.Feedback type="invalid">
                {errors.tanggalSurat}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="perihal">
              <Form.Label>Perihal</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Masukan Perihal"
                value={formData.perihal}
                onChange={(e) =>
                  setFormData({ ...formData, perihal: e.target.value })
                }
                isInvalid={!!errors.perihal}
              />
              <Form.Control.Feedback type="invalid">
                {errors.perihal}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>{" "}
          <Col md={6} className="mb-3">
            <Form.Group id="tanggalDiterima">
              <Form.Label>Diterima Tanggal</Form.Label>

              <Form.Control
                type="date"
                placeholder="Tanggal Diterima"
                value={formData.tanggalDiterima}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    tanggalDiterima: e.target.value,
                  })
                }
                isInvalid={!!errors.tanggalDiterima}
              />
              <Form.Control.Feedback type="invalid">
                {errors.tanggalDiterima}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="isiRingkas">
              <Form.Label>Isi Ringkas</Form.Label>
              <Form.Control
                required
                as="textarea" rows="3"
                placeholder="Masukan Isi Ringkas"
                value={formData.isiRingkas}
                onChange={(e) =>
                  setFormData({ ...formData, isiRingkas: e.target.value })
                }
                isInvalid={!!errors.isiRingkas}
              />
              <Form.Control.Feedback type="invalid">
                {errors.isiRingkas}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>{" "}
          <Col md={6} className="mb-3">
            <Form.Group id="fileSurat">
              <Form.Label>File Surat</Form.Label>
              <Form.Control
                required
                type="file"
                accept="application/pdf"
                onChange={(e) =>
                  setFormData({ ...formData, fileSurat: e.target.files[0] })
                }
                isInvalid={!!errors.fileSurat}
              />
              <Form.Control.Feedback type="invalid">
                {errors.fileSurat}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="sifatSurat">
              <Form.Label>Sifat Surat</Form.Label>
              <Form.Control
                required
                as="select"
                value={formData.sifatSurat}
                onChange={(e) =>
                  setFormData({ ...formData, sifatSurat: e.target.value })
                }
                isInvalid={!!errors.sifatSurat}
              >
                <option value="">Pilih Sifat Surat</option>
                <option value="Segera">Segera</option>
                <option value="Penting">Penting</option>
                <option value="Biasa">Biasa</option>
                <option value="Sangat Segera">Sangat Segera</option>
                <option value="Rahasia">Rahasia</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.sifatSurat}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>{" "}
          <Col md={6} className="mb-3">
            <Form.Group id="keterangan">
              <Form.Label>Keterangan</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Masukan Keterangan"
                value={formData.keterangan}
                onChange={(e) =>
                  setFormData({ ...formData, keterangan: e.target.value })
                }
                isInvalid={!!errors.keterangan}
              />
              <Form.Control.Feedback type="invalid">
                {errors.keterangan}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="tujuansurat">
              <Form.Label>Tujuan Surat</Form.Label>
              <Form.Control
                as="select"
                isInvalid={!!errors.tujuanSurat}
                value={formData.tujuanSurat}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    tujuanSurat: e.target.value,
                  })
                } // Update tujuanSurat in formData
              >
                 {options.map((option, index) => (
                  <option key={index} value={`${option.user.id}`}>
                    {option.user.name} [{option.role.nama}] 
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.tujuanSurat}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>{" "}
          <Col md={6} className="mb-3">
            <Form.Group id="namaPenerima">
              <Form.Label>Nama Penerima</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Masukan Nama Penerima"
                value={formData.namaPenerima}
                onChange={(e) =>
                  setFormData({ ...formData, namaPenerima: e.target.value })
                }
                isInvalid={!!errors.namaPenerima}
              />
              <Form.Control.Feedback type="invalid">
                {errors.namaPenerima}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="tanggalCetak">
              <Form.Label>Tanggal Cetak</Form.Label>
              <Form.Control
                type="date"
                placeholder="Tanggal Cetak"
                value={formData.tanggalCetak}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    tanggalCetak: e.target.value,
                  })
                }
                isInvalid={!!errors.tanggalCetak}
              />
              <Form.Control.Feedback type="invalid">
                {errors.tanggalCetak}
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {errors.tanggalCetak}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>{" "}
          <Col md={6} className="mb-3">
            <Form.Group id="noTelpPenerima">
              <Form.Label>No Telp Penerima</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Masukan No Telp Penerima"
                value={formData.noTelpPenerima}
                onChange={(e) =>
                  setFormData({ ...formData, noTelpPenerima: e.target.value })
                }
                isInvalid={!!errors.noTelpPenerima}
              />
              <Form.Control.Feedback type="invalid">
                {errors.noTelpPenerima}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <div className="mt-3">
          <Button
            variant="primary"
            disabled={loading}
            onClick={handleFormSubmit}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Simpan"
            )}
          </Button>
        </div>
      </>
    );
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form onSubmit={handleFormSubmit}>{CreateSuratMasuk()}</Form>
      </Card.Body>
    </Card>
  );
};
